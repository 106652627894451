import React, { useState, useContext, useEffect } from "react";
import * as S from './PlusAnualStyle';
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loader";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext";
import { formatDate, formatDateClients, formatNumber } from "../ASSETS/assets";

const base_route = process.env.REACT_APP_API_BASE_URL;
const ADD_PLUS = process.env.REACT_APP_ADICIONAR_PLUS_ANUAL;

export default function PlusAnual() {
    const { clientsForSelect, getPurchases, purchases, purchaseForSelect, authState } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedContract, setSelectedContract] = useState(null);
    const [plusValue, setPlusValue] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };


    const fetchContract = async () => {
        setLoading(true);
        try {
            await getPurchases(100, 0, "asc", "", searchTerm);
        } catch (e) {
            console.log(e)
        }
        setLoading(false);
    };

    useEffect(() => {
        setSelectedContract(purchaseForSelect)
    }, [purchaseForSelect])

    const handleSelectContract = (contract) => {
        setSelectedContract(contract);
        setSearchTerm("");
    };

    const handleUnSelectContract = () => {
        setSelectedContract(null);
    };

    const handleSendPlus = async () => {
        if (!plusValue || isNaN(plusValue)) {
            alert("Por favor, insira um valor válido.");
            return;
        }

        const headers = {
            headers: {
                Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`
            }
        }

        setLoading(true);

        try {
            await axios.post(`${base_route}${ADD_PLUS}`, {
                docId: selectedContract.CLIENT_CPF,
                IDCOMPRA: selectedContract.IDCOMPRA,
                PLUS: parseFloat(plusValue)
            }, headers).then(res => {
                alert("PLUS adicionado com sucesso.")
                console.log(res);
                setPlusValue("");
                setSelectedContract(null);
                setLoading(false);
            }).catch(error => {
                console.log(error);
                alert("Erro ao adicionar PLUS")
                setPlusValue("");
                setSelectedContract(null);
                setLoading(false);
            });

        } catch (error) {
            alert(`Erro ao adicionar PLUS: ${error.message}`);
            setLoading(false);
        }
    };

    return (
        <S.PLUSContainer>
            <Loading load={loading} />
            <h1>ADICIONAR PLUS</h1>

            <S.ClientSearch>
                <h4>CLIENTE</h4>
                <div className="inputBoxFilter">
                    <input
                        type="text"
                        placeholder="NOME OU CPF"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
            </S.ClientSearch>

            <S.ButtonSearch>
                <button onClick={fetchContract}>Procurar</button>
            </S.ButtonSearch>

            <S.SearchedClients>
                <div className="columns">
                    <span>ID COMPRA</span>
                    <span>CONTRATOS</span>
                    <span>VALOR CONTRATO</span>
                    <span>NOME CLIENTE</span>
                    <span>CPF CLIENTE</span>
                    <span>USUÁRIO CLIENTE</span>
                    <span>DATA DA COMPRA</span>
                    <span>FIM DO CONTRATO</span>
                </div>
                {selectedContract && (
                    <>
                        <div className="rowSelected" style={{ borderTop: "2px solid white", borderBottom: "2px solid white" }}>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.IDCOMPRA}</span>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.COINS}</span>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && ("R$" + formatNumber(selectedContract.TOTALSPENT))}</span>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.CLIENT_NAME}</span>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.CLIENT_CPF}</span>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.CLIENT_USERNAME}</span>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && (formatDate(selectedContract.PURCHASEDATE) ? formatDate(selectedContract.PURCHASEDATE) : selectedContract.PURCHASEDATE)}</span>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && (formatDate(selectedContract.YIELDTERM) ? formatDate(selectedContract.YIELDTERM) : selectedContract.YIELDTERM)}</span>

                            <span onClick={() => handleUnSelectContract()} style={{ color: "red" }}>x</span>
                        </div>
                    </>
                )}
                {selectedContract === null && (
                    <div className="tabelona">
                        {purchases.map((contract, index) => (
                            <div onClick={() => handleSelectContract(contract)} className="row" style={{ borderTop: "2px solid white", borderBottom: "2px solid white" }}>
                                <span style={{ borderRight: "2px solid white" }}>{contract && contract.IDCOMPRA}</span>
                                <span style={{ borderRight: "2px solid white" }}>{contract && contract.COINS}</span>
                                <span style={{ borderRight: "2px solid white" }}>{contract && ("R$" + formatNumber(contract.TOTALSPENT))}</span>
                                <span style={{ borderRight: "2px solid white" }}>{contract && contract.CLIENT_NAME}</span>
                                <span style={{ borderRight: "2px solid white" }}>{contract && contract.CLIENT_CPF}</span>
                                <span style={{ borderRight: "2px solid white" }}>{contract && contract.CLIENT_USERNAME}</span>
                                <span style={{ borderRight: "2px solid white" }}>{contract && (formatDate(contract.PURCHASEDATE) ? formatDate(contract.PURCHASEDATE) : contract.PURCHASEDATE)}</span>
                                <span style={{ borderRight: "2px solid white" }}>{contract && (formatDate(contract.YIELDTERM) ? formatDate(contract.YIELDTERM) : contract.YIELDTERM)}</span>
                            </div>
                        ))}
                    </div>
                )}

            </S.SearchedClients>

            {selectedContract && (
                <>
                    <h4>Selecionado: #{selectedContract.IDCOMPRA} DE {selectedContract.CLIENT_NAME}</h4>
                    <S.QuandoDeseja>
                        <span>QUANTO SERÁ O PLUS DO CONTRATO? (%)</span>
                        <input
                            type="number"
                            value={plusValue}
                            onChange={(e) => setPlusValue(e.target.value)}
                        />
                        <button onClick={handleSendPlus}>ADICIONAR</button>
                    </S.QuandoDeseja>
                </>
            )}
        </S.PLUSContainer>
    );
}
