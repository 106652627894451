import React, { useState, useEffect, useContext } from "react";
import * as S from "./ModalConsultorStyle";
import axios from "axios";
import { AuthContext } from "../../../Context/AuthContext";
import Loading from "../../Loader";


export default function ModalConsultor({ consultor, onClose }) {
    const { authState } = useContext(AuthContext);
    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [load, setLoad] = useState(false);

    const handleClose = () => {
        setName("");
        setId("");
        setEmail("");
        setContact("");
        onClose();
    }

    useEffect(() => {
        if(consultor){
            setName(consultor.NAME);
            setId(consultor.ID);
            setEmail(consultor.EMAIL);
            setContact(consultor.CONTACT);
        }
    }, [consultor])

    const handleConfirm = async () => {
        if (!name.trim() || !id.trim() || !email.trim() || !contact.trim()) {
            return alert("Nenhum campo pode ser vazio.");
        }
    
        setLoad(true);
    
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_EDITAR_CONSULTOR}`,
                {
                    ID: id.trim(),
                    NAME: name.trim(),
                    EMAIL: email.trim(),
                    CONTACT: contact.trim()
                },
                {
                    headers: { 
                        Authorization: `Bearer ${authState.accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            if (response.status === 200) {  
                alert("Consultor editado com sucesso!");
                handleClose();
            } else {
                alert("Resposta inesperada do servidor.");
            }
        } catch (error) {
            console.error("Erro ao editar consultor:", error);
            
            if (error.response) {
                if (error.response.status === 404) {
                    alert("Consultor não encontrado.");
                } else if (error.response.data?.error) {
                    alert(error.response.data.error);
                } else {
                    alert("Erro ao editar consultor. Por favor, tente novamente.");
                }
            } else {
                alert("Erro de conexão. Verifique sua internet e tente novamente.");
            }
        } finally {
            setLoad(false);
        }
    };

    const handleDeleteConsultor = async () => {
        const consultorId = consultor.ID;

        if (!consultorId) {
            return alert("ID do consultor é necessário.");
        }
    
        if (!window.confirm("Tem certeza que deseja deletar este consultor?")) {
            return;
        }
    
        setLoad(true);
    
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_DELETAR_CONSULTOR}?id=${consultorId}`,
                {
                    headers: { 
                        Authorization: `Bearer ${authState.accessToken}`
                    }
                }
            );
    
    
            if (response.status === 200) {
                alert("Consultor deletado com sucesso!");
                onClose(); 
            }
        } catch (error) {
            console.error("Erro ao deletar consultor:", error);
            
            if (error.response) {
                if (error.response.status === 404) {
                    alert("Consultor não encontrado.");
                } else {
                    alert(error.response.data?.error || "Erro ao deletar consultor.");
                }
            } else {
                alert("Erro de conexão. Verifique sua internet.");
            }
        } finally {
            setLoad(false);
        }
    };

    return (
        <>
            <Loading load={load} />
            <S.CriarContainer>
                <S.Voltar onClick={handleClose}> &lt;  Voltar</S.Voltar>
                <S.Excluir onClick={handleDeleteConsultor}>Excluir</S.Excluir>
                <S.ModalContent>
                    <div className="headerContent">
                        <span className="title">Página Do Consultor</span>
                        <span className="subtitle">Campos com "*" não podem ser editados.</span>
                    </div>

                    <S.PrencherInformacoes>
                        <S.BoxInformacao>
                            <span className="name">NOME</span>
                            <input value={name} onChange={(e) => setName(e.target.value.toUpperCase())} className="inputValue" placeholder="Nome Completo" />
                        </S.BoxInformacao>
                        <S.BoxInformacao>
                            <span className="name">ID *</span>
                            <input disabled value={id} onChange={(e) => setId(e.target.value)} className="inputValue" placeholder="CPF, ID...." />
                        </S.BoxInformacao>
                        <S.BoxInformacao>
                            <span className="name">EMAIL</span>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} className="inputValue" placeholder="" />
                        </S.BoxInformacao>
                        <S.BoxInformacao>
                            <span className="name">CONTATO</span>
                            <input value={contact} onChange={(e) => setContact(e.target.value)} className="inputValue" placeholder="DDD + Número" />
                        </S.BoxInformacao>
                    </S.PrencherInformacoes>
                    <S.ModalButton>
                        <button className={!(consultor.NAME != name.trim() || consultor.EMAIL != email.trim() || consultor.CONTACT != contact.trim()) ? "disabled" : "edited"} onClick={(consultor.NAME != name.trim() || consultor.EMAIL != email.trim() || consultor.CONTACT != contact.trim()) ? handleConfirm : console.log("")}>Confirmar</button>
                    </S.ModalButton>
                </S.ModalContent>
            </S.CriarContainer>
        </>
    )
}