import React, { useContext, useState } from "react";
import ModalContainer from '../ModalContainer/ModalContainer';
import * as S from './ModalNovoSaqueStyle';
import axios from "axios";
import { addSaque } from "../../redux/actions";
import Loading from "../Loader";
import { AuthContext } from "../../Context/AuthContext";
import { formatNumber } from "../ASSETS/assets";

const base_url = process.env.REACT_APP_API_BASE_URL;
const rota_url = process.env.REACT_APP_API_CRIAR_SAQUE_ADM2;

export default function ModalNovoSaque({ setModalNovoSaque }) {
    const { getPurchasesToWithdraw, purchasesForWithdraw, authState, logout } = useContext(AuthContext);
    const [clientSearch, setClientSearch] = useState('');
    const [saqueValor, setSaqueValor] = useState(150);
    const [load, setLoad] = useState(false);
    const [selectedContract, setSelectedContract] = useState(null);

    const handleSaqueChange = (e) => {
        let value = e.target.value;
        value = value.replace(/[^0-9,.]/g, '');
        value = value.replace(',', '.');
        setSaqueValor(value);
    };

    const fetchContracts = async () => {
        setLoad(true);
        try {
            await getPurchasesToWithdraw(300, 0, "asc", "", clientSearch);
        } catch (e) {
            console.log(e)
        }
        setLoad(false);
    };

    const handleUnSelectContract = () => {
        setSelectedContract(null);
    }

    const realizeSaque = async () => {

        if (parseFloat(saqueValor) > selectedContract.DISPONIVEL_PARA_SAQUE) {
            alert("Valor indisponível para saque");
            return;
        }

        setLoad(true);

        try {
            const response = await axios.post(`${base_url}${rota_url}`, {
                USERNAME: selectedContract.CLIENT_USERNAME,
                CPF: selectedContract.CLIENT_CPF,
                saqueData: {
                    STATUS: 2,
                    CODCLI: selectedContract.CLIENT_CPF,
                    VALORSOLICITADO: parseFloat(saqueValor),
                    IDCOMPRA: selectedContract.IDCOMPRA
                },
            }, {
                headers: { Authorization: `Bearer ${authState.accessToken}` }
            });

            if (response.data.status === 201) {
                setModalNovoSaque(false);
                setLoad(false);
                alert("Saque feito com sucesso, disponível em SAQUES FEITOS");
            }

        } catch (error) {
            console.log(error);
            setLoad(false);
            if (error.response.status === 401 || error.response.status === 403) {
                alert("Sessão Expirada");
                logout();
                setLoad(false);
            } else {
                alert('Erro: ' + error.message);
            }
        }
    };

  
    return (
        <ModalContainer>
            {<Loading load={load} />}
            <S.ModalContent>
                <S.FecharModal>
                    <button onClick={() => setModalNovoSaque(false)}>Fechar</button>
                </S.FecharModal>
                <S.ModalTitle>NOVO SAQUE</S.ModalTitle>

                <S.Searching>
                    <div>
                        <p>PESQUISE PELO CLIENTE</p>
                        <div className="inputBoxSearch">
                            <input
                                placeholder="NOME OU CPF"
                                value={clientSearch}
                                onChange={e => setClientSearch(e.target.value)}
                            />
                            <button onClick={fetchContracts}>PESQUISAR</button>
                        </div>

                        {purchasesForWithdraw && (
                            <>
                                <S.SearchedClients>
                                    <div className="columns">
                                        <span>ID</span>
                                        <span>NOME</span>
                                        <span>USUÁRIO</span>
                                        <span>CPF</span>
                                        <span>DISPONÍVEL</span>
                                    </div>
                                    {selectedContract && (
                                        <>
                                            <div className="rowSelected" style={{ borderTop: "2px solid white", borderBottom: "2px solid white" }}>
                                                <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.IDCOMPRA}</span>
                                                <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.CLIENT_NAME}</span>
                                                <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.CLIENT_USERNAME}</span>
                                                <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.CLIENT_CPF}</span>

                                                <span style={{ borderRight: "2px solid white" }}>R${selectedContract && formatNumber(selectedContract.DISPONIVEL_PARA_SAQUE)}</span>
                                                <span onClick={() => handleUnSelectContract()} style={{ color: "red" }}>x</span>
                                            </div>
                                        </>
                                    )}
                                    {selectedContract === null && (
                                        <div className="tabelona">
                                            {purchasesForWithdraw.map((contract, index) => (
                                                <div onClick={() => { contract.DISPONIVEL_PARA_SAQUE > 0 ? setSelectedContract(contract) : alert("Este contrato não possui saldo.") }} className="row" style={{ borderTop: "2px solid white", borderBottom: "2px solid white" }}>
                                                    <span style={{ borderRight: "2px solid white" }}>{contract && contract.IDCOMPRA}</span>
                                                    <span style={{ borderRight: "2px solid white" }}>{contract && contract.CLIENT_NAME}</span>
                                                    <span style={{ borderRight: "2px solid white" }}>{contract && contract.CLIENT_USERNAME}</span>
                                                    <span style={{ borderRight: "2px solid white" }}>{contract && contract.CLIENT_CPF}</span>

                                                    <span style={{ borderRight: "2px solid white" }}>R${contract && formatNumber(contract.DISPONIVEL_PARA_SAQUE || 0)}</span>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                </S.SearchedClients>
                            </>

                        )}
                    </div>
                </S.Searching>
                {/* <S.SearchClient>
                    <span>PROCURE PELO CLIENTE</span>
                    <input
                        type="text"
                        placeholder="NOME OU CPF"
                        value={clientSearch}
                        onChange={(e) => setClientSearch(e.target.value)}
                    />
                    {clientSearch && (
                        <S.SelecionarClienteBox>
                            {filteredClients.length > 0 ? (
                                filteredClients.map(client => (
                                    <S.Cliente
                                        key={client.CPF}
                                        onClick={() => handleSelectClient(client)}
                                    >
                                        {client.NAME} - {client.CPF}
                                    </S.Cliente>
                                ))
                            ) : (
                                <S.Cliente>Cliente não encontrado.</S.Cliente>
                            )}
                        </S.SelecionarClienteBox>
                    )}


                </S.SearchClient> */}

                {selectedContract && (
                    <>
                        <S.SacarDoSelecionado>
                            <span className="titulo">DIGITE O VALOR A SER SACADO</span>
                            <input className="inputSacar" value={saqueValor} onChange={handleSaqueChange} placeholder="0,00" />
                            <button className="buttonSacar" onClick={realizeSaque}>SACAR</button>
                        </S.SacarDoSelecionado>
                    </>
                )}
                {/* {selectedClient && (


                    <S.ClienteSelecionado>
                        <S.ClienteInfo>
                            <span>NOME</span>
                            <input type="text" value={selectedClient.NAME} readOnly />
                        </S.ClienteInfo>

                        <S.ClienteInfo>
                            <span>CPF</span>
                            <input type="text" value={selectedClient.CPF} readOnly />
                        </S.ClienteInfo>

                        <S.ClienteInfo>
                            <span>{selectedContract ? `CONTRATO SELECIONADO: #${selectedContract.IDCOMPRA}` : "SELECIONE UM CONTRATO"}</span>
                            <div className="tabelaColunas">
                                <div className="coluna">ID</div>
                                <div className="coluna">VALOR DISPONÍVEL</div>
                                <div className="coluna">STATUS</div>
                            </div>
                            <div className="tabelaLinhas">
                                {selectedClient.CONTRATOS && handleReturnContracts(selectedClient.CONTRATOS).map(c => (
                                    <div onClick={() => { setSelectedContract(c) }} className="Linhas">
                                        <div className="itemLinha">{c.IDCOMPRA || ""}</div>
                                        <div className="itemLinha">R${c.DISPONIVEL.toFixed(2) || ""}</div>
                                        <div className="itemLinha">{c.STATUS === 1 ? 'Valorizando' : `Finalizado`}</div>
                                    </div>
                                    // <h6 onClick={() => { setSelectedContract(c) }}>ID: {c.IDCOMPRA}, LUCRO DISPONÍVEL: R${c.DISPONIVEL.toFixed(2)}, STATUS: {c.STATUS === 1 ? 'Valorizando' : `Finalizado, SALDO RECOMPRA: R$${c.TOTALSPENT}`}</h6>
                                ))}
                            </div>

                        </S.ClienteInfo>
                    </S.ClienteSelecionado>
                )}

                {selectedContract && (
                    <>
                        <S.ClienteInfo style={{marginTop: 20}}>
                            <span>VALOR DO SAQUE (R$)</span>
                            <input
                                type="text"
                                value={saqueValor}
                                onChange={handleSaqueChange}
                            />
                        </S.ClienteInfo>

                        <S.ButtonSaque>
                            <button onClick={realizeSaque}>REALIZAR SAQUE</button>
                        </S.ButtonSaque>
                    </>

                )} */}

            </S.ModalContent>
        </ModalContainer>
    );
}
