import React, { useContext, useEffect, useState } from "react";
import * as S from './SaquesStyle';
import ValidarCredenciais from "../ValidarCredenciais/ValidarCredenciais";
import ModalNovoSaque from "./ModalNovoSaque";
import { formatCPF, formatDate, formatNumber } from "../ASSETS/assets";
import Loading from "../Loader";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function Saques() {
    const { withdrawalsPending, totalWithdrawalsPending, getSaquesPending, authState } = useContext(AuthContext);
    const [search, setSearch] = useState('');
    const [modalAberto, setModalAberto] = useState(false);
    const [modalData, setModalData] = useState({});
    const [modalNovoSaque, setModalNovoSaque] = useState(false);
    const [load, setLoad] = useState(false);
    const [sortOrder, setSortOrder] = useState('desc');
    const [modalConta, setModalConta] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterPages, setFilterPages] = useState(30);
    const [offset, setOffset] = useState(0);
    const [itemsPerPage, setItemPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = (currentPage - 1) * itemsPerPage;
    const navigate = useNavigate();
    const currentSaques = withdrawalsPending.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        if (authState.permission != "ALL" && !authState.permission.includes("CRIAR/EDITAR/AUTORIZAR SAQUES")) {
            navigate("/")
        }
        if (withdrawalsPending.length === 0) {
            fetchSaques();
        }
    }, [])

    const fetchSaques = async () => {
        setLoad(true);
        try {
            console.log(filterPages, offset, sortOrder, search)
            await getSaquesPending(filterPages, offset, sortOrder, search);
        } catch (e) {
            console.log(e)
        }
        setLoad(false);
    };

    const handlePaginateFuture = async (type) => {
        var totalSaquesCarregados = currentPage * itemsPerPage;
        if (type === "+") {
            if (totalSaquesCarregados <= ((currentPage + 1) * itemsPerPage)) {
                setLoad(true)
                setFilterPages(filterPages + (itemsPerPage * 2))
                await fetchSaques();
                setLoad(false);
            }
            setCurrentPage(currentPage + 1);
        } else {
            if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
            }
        }
    }

    const handleStatus = (status) => {
        switch (status) {
            case 1:
                return 'Pendente';
            case 2:
                return 'Pago';
            case 3:
                return 'Expirado';
            case 4:
                return 'Cancelado';
            default:
                return 'Indefinido';
        }
    };

    const handleOpenValidarModal = (data) => {
        setModalData(data);
        setModalAberto(true);
    };

    return (
        <>
            <S.SaquesContainer>
                {modalAberto && (
                    <ValidarCredenciais
                        modalData={modalData}
                        setModalAberto={setModalAberto}
                        type="SAQUE"
                    />
                )}

                {modalConta && (
                    <S.ModalConta>
                        <div className="box">
                            <span onClick={() => setModalConta(null)} className="close">x</span>
                            <span className="title">{modalConta && `Conta de ${modalConta.CLIENT_NAME}`}</span>

                            <div className="account">
                                <div className="boxzinha">BANCO: {modalConta.BANCO ? modalConta.BANCO : modalConta.BANK ? modalConta.BANK : 'sem banco'}</div>
                                <div className="boxzinha">AGÊNCIA: {modalConta.CLIENT_AGENCY ? modalConta.CLIENT_AGENCY : 'sem agência'}</div>
                                <div className="boxzinha">CONTA: {modalConta.CLIENT_ACCOUNT ? modalConta.CLIENT_ACCOUNT : 'sem conta'}</div>
                                <div className="boxzinha">TIPO: {modalConta.CLIENT_ACCOUNTTYPE ? modalConta.CLIENT_ACCOUNTTYPE : 'sem definição'}</div>
                                <div className="boxzinha">PIX: {modalConta.CLIENT_KEYPIX ? formatCPF(modalConta.CLIENT_KEYPIX) : 'sem pix'}</div>
                            </div>
                        </div>
                    </S.ModalConta>
                )}

                {modalNovoSaque && (
                    <ModalNovoSaque setModalNovoSaque={setModalNovoSaque} />
                )}

                <Loading load={load} />

                <S.SaquesFirstContent>
                    <S.AreaTitle>VALIDAR SAQUES</S.AreaTitle>
                    <S.AddSaques onClick={() => setModalNovoSaque(true)}>+ REALIZAR NOVO SAQUE</S.AddSaques>
                </S.SaquesFirstContent>

                <S.SaquesContent>

                    <S.Filtros>
                        <S.SearchBar>
                            <input
                                value={search}
                                onChange={e => {
                                    setSearch(e.target.value);
                                }}
                                type="text"
                                placeholder="FILTRAR"
                            />

                            <button onClick={() => { fetchSaques(); setCurrentPage(1) }}> PESQUISAR</button>
                        </S.SearchBar>

                        <S.FiltroStatus>
                            <div className="box">
                                <span className="title">ORDEM</span>
                                <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} className="selectBox">
                                    <option value="desc">Descrescente</option>
                                    <option value="asc">Crescente</option>
                                </select>
                                <button onClick={() => { fetchSaques(); setCurrentPage(1) }} className='filterButton'>Filtrar</button>
                            </div>
                        </S.FiltroStatus>
                    </S.Filtros>

                    <S.SaquesTable>
                        <S.TableContainer>
                            <S.Table>
                                <S.TableHeader>
                                    <S.TableRow>
                                        <S.TableHeaderCell>CLIENTE</S.TableHeaderCell>
                                        <S.TableHeaderCell>CPF</S.TableHeaderCell>
                                        <S.TableHeaderCell>USUÁRIO</S.TableHeaderCell>
                                        <S.TableHeaderCell>DATA SOLICITAÇÃO</S.TableHeaderCell>
                                        <S.TableHeaderCell>VALOR</S.TableHeaderCell>
                                        <S.TableHeaderCell>VALOR + TAXA</S.TableHeaderCell>
                                        <S.TableHeaderCell>CONTA</S.TableHeaderCell>
                                        <S.TableHeaderCell>FUNDOS</S.TableHeaderCell>
                                        <S.TableHeaderCell>APROVADO</S.TableHeaderCell>
                                        <S.TableHeaderCell>OPÇÕES</S.TableHeaderCell>
                                    </S.TableRow>
                                </S.TableHeader>
                                <S.TableBody>
                                    {currentSaques.map((user, index) => (
                                        <S.TableRow key={index}>
                                            <S.TableCell>{user.CLIENT_NAME}</S.TableCell>
                                            <S.TableCell>{user.CLIENT_CPF}</S.TableCell>
                                            <S.TableCell>{user.CLIENT_USERNAME}</S.TableCell>
                                            <S.TableCell>{formatDate(user.DATASOLICITACAO)}</S.TableCell>
                                            <S.TableCell>R$ {(user.VALORSOLICITADO ? formatNumber(user.VALORSOLICITADO) : user.VALOR ? formatNumber(user.VALOR) : 0)}</S.TableCell>
                                            <S.TableCell>R$ {user.VALORSOLICITADO ? (formatNumber(user.VALORSOLICITADO - (user.VALORSOLICITADO * 0.04))) : formatNumber(user.VALOR - (user.VALOR * 0.04))}</S.TableCell>
                                            <S.TableCell><button onClick={() => setModalConta(user)} className="seeAccount button">Ver Conta</button></S.TableCell>
                                            <S.TableCell>{user.IDCOMPRA ? `#${user.IDCOMPRA}` : "Não Informado"}</S.TableCell>
                                            <S.TableCell>{handleStatus(user.STATUS)}</S.TableCell>
                                            <S.TableCell>
                                                <S.OptionsButtons>
                                                    <img
                                                        onClick={() => { handleOpenValidarModal({...user, search}) }}
                                                        src='pay-ico-saque.png' alt="payIco"
                                                    />
                                                </S.OptionsButtons>
                                            </S.TableCell>
                                        </S.TableRow>
                                    ))}
                                </S.TableBody>
                            </S.Table>
                        </S.TableContainer>
                    </S.SaquesTable>

                    <S.PaginationContainer>
                        <S.PaginationButton onClick={() => { handlePaginateFuture("-") }}>
                            Anterior
                        </S.PaginationButton>

                        <S.PaginationButton>
                            {indexOfFirstItem} à {currentPage * itemsPerPage} de {totalWithdrawalsPending}
                        </S.PaginationButton>

                        <S.PaginationButton onClick={() => { handlePaginateFuture("+") }}>
                            Próxima
                        </S.PaginationButton>
                    </S.PaginationContainer>
                </S.SaquesContent>
            </S.SaquesContainer>
        </>
    );
}
