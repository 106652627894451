import React, { useContext, useEffect, useState } from "react";
import * as S from './EnviarMensagemStyle';
import { db } from "../../../DATABASE/firebaseConfig";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import MensagemSchema from "./MensagemSchema";
import Loading from "../../Loader"
import { format } from 'date-fns';
import { AuthContext } from "../../../Context/AuthContext";

const formatFirestoreTimestamp = (timestamp) => {
    if (timestamp && typeof timestamp.toDate === 'function') {
        return format(timestamp.toDate(), 'dd/MM/yyyy HH:mm');
    } else if (timestamp && timestamp.seconds) {
        return format(new Date(timestamp.seconds * 1000), 'dd/MM/yyyy HH:mm');
    } else if (timestamp instanceof Date) {
        return format(timestamp, 'dd/MM/yyyy HH:mm');
    } else if (typeof timestamp === 'string') {
        return timestamp;
    }
    return 'Data não disponível';
};

export default function EnviarMensagem({ onClose }) {
    const { getClients, clientsDataBase } = useContext(AuthContext);
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCPFs, setSelectedCPFs] = useState([]);
    const [clients, setClients] = useState([]);
    const [load, setLoad] = useState(false);

    const fetchClients = async () => {
        setLoad(true);
        try {
            // setLoad(true)
            await getClients(100, 0, searchTerm, "crescente", "");
            // setLoad(false)
        } catch (e) {
            // setLoad(false)
            console.log(e);
        }
        setLoad(false);

    };

    useEffect(() => {
        if (clientsDataBase) {
            setClients(clientsDataBase)
        }
    }, [clientsDataBase])

    const getMessages = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "MENSAGENS"));
            const messagesData = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    mensagem: data.mensagem,
                    titulo: data.titulo || "",
                    tipo: data.tipo,
                    link: data.link || "",
                    data: formatFirestoreTimestamp(data.data),
                    imagem: data.imagem || "",
                    ENVIAR_PARA: data.ENVIAR_PARA || []
                };
            });
            setMessages(messagesData);
        } catch (error) {
            console.error("Erro ao buscar mensagens: ", error);
        }
    };

    useEffect(() => {
        getMessages();
    }, []);

    const handleClick = (messageData) => {
        setSelectedMessage(messageData);
    };

    const handleExitModalEnviar = () => {
        setSelectedMessage(null);
        setSearchTerm('');
        setSelectedCPFs([]);
    };

    useEffect(() => {
        if (selectedMessage) {
            if (selectedMessage.ENVIAR_PARA && selectedMessage.ENVIAR_PARA.length > 0 && selectedCPFs.length === 0) {
                setSelectedCPFs(selectedMessage.ENVIAR_PARA);
            }
        }
    }, [selectedMessage]);

    const handleEnviarMensagens = async () => {
        if (!selectedMessage || !selectedMessage.id) {
            console.error("Nenhuma mensagem selecionada ou id da mensagem não encontrado.");
            console.log(selectedMessage.id)
            return;
        }

        try {
            const messageRef = doc(db, "MENSAGENS", selectedMessage.id);

            if (searchTerm.trim() === "*") {
                await updateDoc(messageRef, {
                    ENVIAR_PARA: ["*"]
                });
            } else {
                await updateDoc(messageRef, {
                    ENVIAR_PARA: selectedCPFs
                });
            }

            alert("Mensagens enviadas com sucesso.");
            console.log("Mensagens enviadas com sucesso.");

            setSelectedMessage(null);
            setSearchTerm('');
            setSelectedCPFs([]);
        } catch (error) {
            console.error("Erro ao enviar mensagens: ", error);
        }
    };

    const handleAddOrRemove = (item) => {
        if (!selectedCPFs.includes(item)) {
            setSelectedCPFs([...selectedCPFs, item]);
        } else {
            setSelectedCPFs(selectedCPFs.filter(c => c != item));
        }
    }


    return (
        <>
            <S.ModalOverlay>
                <S.ModalContent>

                    <S.CloseButton onClick={onClose}>X</S.CloseButton>
                    <h2>SELECIONE UMA MENSAGEM</h2>
                    <S.AllMessages>
                        {messages.map((message) => (
                            <MensagemSchema
                                key={message.id}
                                data={{
                                    id: message.id,
                                    message: message.mensagem,
                                    title: message.titulo || "",
                                    messageType: message.tipo,
                                    link: message.link || "",
                                    diaData: message.data,
                                    image: message.imagem || "",
                                    ENVIAR_PARA: message.ENVIAR_PARA || []
                                }}
                                handleClick={handleClick}
                            />
                        ))}
                    </S.AllMessages>
                </S.ModalContent>
            </S.ModalOverlay>

            {selectedMessage && (
                <S.ModalEnviar>
                    <Loading load={load} />

                    <S.FecharModalEnviar> <span onClick={handleExitModalEnviar}>x</span></S.FecharModalEnviar>
                    <h3>SELECIONE OS CLIENTES</h3>
                    <S.ShowWicthMessage>
                        <MensagemSchema
                            onClick={() => { console.log('click') }}
                            onDoubleClick={() => { console.log('click') }}
                            data={selectedMessage}
                        />
                    </S.ShowWicthMessage>

                    <S.SearchClient>
                        <p>DIGITE * PARA TODOS OU PESQUISE E SELECIONE</p>
                        <div className="searchBox">
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <button onClick={() => { fetchClients() }} style={{ background: "rgba(0, 80, 200, 1)" }}>PESQUISAR</button>
                            <button onClick={() => { handleEnviarMensagens(); }} style={{ background: "rgba(100, 255, 0, 1)", color: "black" }}>SALVAR</button>
                        </div>
                    </S.SearchClient>

                    <S.ListSelected>
                        <div className="row" style={{ background: "linear-gradient(to right, rgba(20, 20, 20, 1), rgba(20, 20, 20, 1), rgba(20, 20, 20, 1))" }}>
                            <div className="it" style={{ borderRight: "3px solid black" }}>NOME</div>
                            <div className="it" style={{ borderRight: "3px solid black" }}>CPF/CNPJ</div>
                            <div className="it" style={{ borderRight: "3px solid black" }}>USUÁRIO</div>
                            <div className="it"></div>
                        </div>

                        {(!clients || !clients.length > 0) && selectedCPFs.length === 0 && (
                            <>
                                <span className="informe">Nenhum Cliente Selecionado</span>
                            </>
                        )}

                        <S.TabelaClientes>
                            {clients && clients.map(c => (
                                <div className="row" style={{ background: "linear-gradient(to right, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1))" }}>
                                    <div className="it" style={{ borderRight: "3px solid black" }}>{c.NAME}</div>
                                    <div className="it" style={{ borderRight: "3px solid black" }}>{c.CPF}</div>
                                    <div className="it" style={{ borderRight: "3px solid black" }}>{c.USERNAME}</div>
                                    <div onClick={() => handleAddOrRemove(c.USERNAME)} className="it">{selectedCPFs.includes(c.USERNAME) ? "REMOVER" : "ADICIONAR"}</div>
                                </div>
                            ))}
                        </S.TabelaClientes>

                    </S.ListSelected>
                </S.ModalEnviar>
            )}
        </>
    );
}
