import styled from "styled-components";

export const ModalContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999999;
    background: rgba(0,0,0,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModalCenter = styled.div`
    width: 70%;
    height: 80%;
    box-sizing: border-box;
    padding: 30px 40px;
    background: rgba(240, 240, 240, 1);
    border-radius: 6px;
    display: grid;
    grid-template-rows: 2fr 8fr;
    position: relative;

    .voltar{
        position: absolute;
        top: 20px;
        left: 30px;
        font-size: 22px;
        font-weight: 800;
        color: black;
        cursor: pointer;
        transition: .3s;

        &:hover{
            transform: scale(0.97);
        }
    }
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .titulo{
        font-size: 42px;
        font-weight: 800;
        color: black;
    }

    .search{
        display: grid;
        width: 100%;
        grid-template-columns: 8fr 1fr;
        gap: 10px;

        input{
            width: 100%;
            box-sizing: border-box;
            padding: 0;
            padding-left: 30px;
            height: 45px;
            border: 1px solid black;
            font-size: 18px;
            box-shadow: 2px 2px 1px rgba(0,0,0,0.6);
        }

        button{
            width: 100%;
            height: 45px;
            box-sizing: border-box;
            padding: 0;
            cursor: pointer;
            border: 1px solid black;
            background: rgba(80, 220, 0, 1);
            font-size: 18px;
            transition: .3s;
            box-shadow: 2px 2px 1px rgba(0,0,0,0.6);
        }
    }
`;

export const Body = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 14fr;

    .explain{
        font-size: 16px;
        color: rgba(0,0,0,0.6);
    }
`;

export const List = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;

    .rowColumns{
        width: 95%;
        display: grid;
        grid-template-columns: 3fr 5fr 4fr;
        height: 50px;
        border: 2px solid black;
        border-bottom: 2px solid rgba(0,0,0,0);
        background: linear-gradient(-45deg, rgba(50, 50, 50, 1), rgba(30, 30, 30, 1), rgba(50, 50, 50, 1));
        box-sizing: border-box;

        .item{
            width: 100%;
            height: 100%;
            font-size: 20px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
        }
    }

    .rowsContainer{
        width: 95%;
        height: 80%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;

        .row{
            width: 100%;
            display: grid;
            grid-template-columns: 3fr 5fr 4fr;
            height: 40px;
            border-bottom: 2px solid black;
            cursor: pointer;
            transition: .3s;
            box-sizing: border-box;

            .item{
                width: 100%;
                height: 100%;
                font-size: 16px;
                color: black;
                display: flex;
                box-sizing: border-box;
                justify-content: center;
                align-items: center;
            }

            &:hover{
                background: rgba(180, 180, 180, 1);
            }
        }
    }
`;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;
