import React, { useState, useContext, useEffect } from "react";
import * as S from "./ExtrairDadosStyle";
import { AuthContext } from "../../Context/AuthContext";
import Loading from "../Loader";
import { useNavigate } from "react-router-dom";

const clientStatusFilter = [];
const contratosStatusFilter = [
    { name: "Todos", value: "" },
    { name: "Valorizando", value: "1" },
    { name: "Finalizado", value: "2" },
    { name: "Cancelado", value: "3" },
    { name: "Pendente", value: "4" },
];

const saqueStatusFilter = [
    { name: "Todos", value: "" },
    { name: "Pendentes", value: "1" },
    { name: "Pagos", value: "2" },
    { name: "Cancelados", value: "3" },
];


export default function ExtrairDados() {
    const { authState } = useContext(AuthContext);
    const [type, setType] = useState("")
    const [statusFilter, setStatusFilter] = useState("");
    const [orderFilter, setOrderFilter] = useState("desc");
    const [filtroString, setFiltroString] = useState("");
    const [consultorId, setConsultorId] = useState("");
    const [monthFilter, setMonthFilter] = useState("");
    const [yearFilter, setYearFilter] = useState("");
    const { downloadExcel } = useContext(AuthContext);
    const [load, setLoad] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (authState.permission != "ALL" && !authState.permission.includes("EXTRAIR DADOS")) {
            navigate("/")
        }
    }, [])

    const handleChangeType = (e) => {
        setType(e.target.value);
        setStatusFilter("");
        setOrderFilter("desc");
        setConsultorId("");

        if (type === "dinheiroinvestido") {
            setOrderFilter("desc")
        }
    }

    const handleDownload = async () => {
        if (type.trim() === "")
            return alert("Selecione o Dado a Ser Extraido.");
        if (type.trim() === "clientes" && statusFilter.trim() != "")
            setStatusFilter("");
        if (type.trim() === "dinheiroinvestido" && statusFilter.trim() != "") {
            setStatusFilter("");
        }
        if (orderFilter.trim() === "" || orderFilter.trim() != "desc" || orderFilter.trim() != "asc")
            setOrderFilter("desc");

        setLoad(true);
        try {
            await downloadExcel(orderFilter, type != "dinheiroinvestido" ? statusFilter : "", type != "dinheiroinvestido" ? filtroString : "", type, monthFilter, yearFilter, type === "dinheiroinvestido" ? orderFilter : "", consultorId);
        } catch (err) {
            console.log(err);
            alert("Houve um erro ao fazer o download.");
        }
        setLoad(false);
    }

    return (
        <>
            <Loading load={load} />
            <S.ExtrairContainer>
                <S.TypeAndFilter>
                    <S.SelectBox>
                        <span className="title">Extrair: </span>
                        <select value={type} onChange={(e) => handleChangeType(e)} className="selectInfo">
                            <option value="">Selecione</option>
                            <option value="clientes">Clientes</option>
                            <option value="contratos">Contratos</option>
                            <option value="saques">Saques</option>
                            <option value="dinheiroinvestido">Aplicações</option>
                        </select>
                    </S.SelectBox>

                    <S.Filters>

                        {type && type.trim() != "" && type != "clientes" && type != "dinheiroinvestido" ? (
                            <div className="box">
                                <span className="title">Status: </span>
                                <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} className="selectInfo">
                                    {type === "contratos" && contratosStatusFilter.length !== 0 && contratosStatusFilter.map((it, key) => (
                                        <option key={key} value={it.value}>{it.name}</option>
                                    ))}
                                    {type === "saques" && saqueStatusFilter.length !== 0 && saqueStatusFilter.map((it, key) => (
                                        <option key={key} value={it.value}>{it.name}</option>
                                    ))}
                                </select>
                            </div>
                        ) : (
                            <>

                            </>
                        )}

                        {type && type.trim() != "" && type != "dinheiroinvestido" && (
                            <>
                                <div className="box">
                                    <span className="title">Ordenação: </span>
                                    <select value={orderFilter} onChange={(e) => setOrderFilter(e.target.value)} className="selectInfo">
                                        <option value="desc">Decrescente</option>
                                        <option value="asc">Crescente</option>
                                    </select>
                                </div>

                                <div className="box">
                                    <span className="title">Cliente: </span>
                                    <input value={filtroString} onChange={(e) => setFiltroString(e.target.value)} placeholder="NOME OU CPF" className="selectInfo" />
                                </div>

                                {type === "clientes" && (
                                    <div className="box">
                                        <span className="title">Consultor: </span>
                                        <input value={consultorId} onChange={(e) => setConsultorId(e.target.value)} placeholder="ID do consultor" className="selectInfo" />
                                    </div>
                                )}

                                {type.trim() === "saques" && (
                                    <>
                                        <div className="box">
                                            <span className="title">Mês: </span>
                                            <select value={monthFilter} onChange={(e) => setMonthFilter(e.target.value)} className="selectInfo">
                                                <option value="">Todos</option>
                                                <option value="1">Janeiro</option>
                                                <option value="2">Fevereiro</option>
                                                <option value="3">Março</option>
                                                <option value="4">Abril</option>
                                                <option value="5">Maio</option>
                                                <option value="6">Junho</option>
                                                <option value="7">Julho</option>
                                                <option value="8">Agosto</option>
                                                <option value="9">Setembro</option>
                                                <option value="10">Outubro</option>
                                                <option value="11">Novembro</option>
                                                <option value="12">Dezembro</option>
                                            </select>
                                        </div>
                                        <div className="box">
                                            <span className="title">Ano: </span>
                                            <select value={yearFilter} onChange={(e) => setYearFilter(e.target.value)} className="selectInfo">
                                                <option value="">Todos</option>
                                                <option value="2025">2025</option>
                                                <option value="2024">2024</option>
                                                <option value="2023">2023</option>
                                                <option value="2022">2022</option>
                                                <option value="2021">2021</option>
                                                <option value="2020">2020</option>
                                                <option value="2019">2019</option>
                                                <option value="2018">2018</option>
                                            </select>
                                        </div>
                                    </>
                                )}

                            </>
                        )}

                        {type === "dinheiroinvestido" && (
                            <>
                                <div className="box">
                                    <span className="title">Ordenação: </span>
                                    <select value={orderFilter} onChange={(e) => setOrderFilter(e.target.value)} className="selectInfo">
                                        <option value="desc">Decrescente</option>
                                        <option value="asc">Crescente</option>
                                    </select>
                                </div>
                            </>
                        )}
                    </S.Filters>
                    <S.Download>
                        <button onClick={handleDownload}>Download</button>
                    </S.Download>
                </S.TypeAndFilter>
            </S.ExtrairContainer>
        </>
    )
}

