import React, { useEffect, useState, useContext } from 'react';
import * as S from './ConsultoresStyle';
import ModalCriar from './ModalCriar/ModalCriar';
import axios from 'axios';
import Loading from '../Loader';
import { formatCPF, formatDateClients } from '../ASSETS/assets';
import ModalConsultor from './ModalConsultor/ModalConsultor';
import { AuthContext } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function Consultores() {
    const { authState } = useContext(AuthContext);
    const [modalCriarOpen, setModalCriarOpen] = useState(false);
    const [stringFilter, setStringFilter] = useState("");
    const [consultores, setConsultores] = useState([]);
    const [load, setLoad] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [sortOrder, setSortOrder] = useState('desc')
    const [selected, setSelected] = useState(null);
    const navigate = useNavigate();


    const getConsultores = async (page = 1, filter = "", order = sortOrder) => {
        setLoad(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_OBTER_CONSULTOR}`,
                {
                    params: {
                        page,
                        limit: 10,
                        filterString: filter,
                        sortOrder: order
                    }
                }
            );

            if (response.status === 200) {
                setConsultores(response.data.consultores);
                setTotalItems(response.data.total);
                setCurrentPage(response.data.page);
                setSortOrder(response.data.sortOrder);
            }
        } catch (err) {
            console.error("Erro ao buscar consultores:", err);
            alert("Erro ao obter consultores");
        } finally {
            setLoad(false);
        }
    }

    useEffect(() => {
        if (authState.permission != "ALL" && !authState.permission.includes("GARENCIAR CONSULTORES")) {
            navigate("/")
        }
        getConsultores();
    }, []);

    // Funções de paginação
    const goToPage = (page) => {
        getConsultores(page, stringFilter);
    };

    const nextPage = () => {
        if (currentPage < Math.ceil(totalItems / itemsPerPage)) {
            goToPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            goToPage(currentPage - 1);
        }
    };

    const firstPage = () => {
        goToPage(1);
    };

    const lastPage = () => {
        goToPage(Math.ceil(totalItems / itemsPerPage));
    };

    // Índices dos itens exibidos
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage + 1;

    return (
        <>
            <Loading load={load} />
            {modalCriarOpen && (
                <ModalCriar onClose={() => { getConsultores(1, stringFilter); setModalCriarOpen(false) }} />
            )}
            {selected && (
                <ModalConsultor consultor={selected} onClose={() => { getConsultores(1, stringFilter); setSelected(null) }} />
            )}
            <S.ConsultoresContainer>
                <S.CreateButton>
                    <button onClick={() => setModalCriarOpen(true)}>Cadastrar Consultor</button>
                </S.CreateButton>
                <S.Title>Consultores</S.Title>

                <S.ConsultoresTableContainer>
                    <S.SearchBar>
                        <input
                            type="text"
                            placeholder="Nome ou ID do consultor"
                            value={stringFilter}
                            onChange={(e) => setStringFilter(e.target.value)}
                        />
                        <button onClick={() => getConsultores(1, stringFilter)}>PESQUISAR</button>
                    </S.SearchBar>

                    <S.SearchOrdem>
                        <div className='box'>
                            <span className='title'>ORDEM</span>
                            <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} className='selectFilter'>
                                <option value="desc">Decrescente</option>
                                <option value="asc">Crescente</option>
                            </select>
                            <button onClick={() => getConsultores(1, stringFilter)} className='filterButton'>Filtrar</button>
                        </div>
                    </S.SearchOrdem>

                    <S.ConsultoresTable>
                        <S.TableContainer>
                            <S.Table>
                                <S.TableHeader>
                                    <S.TableRow>
                                        <S.TableHeaderCell>ID</S.TableHeaderCell>
                                        <S.TableHeaderCell>NOME</S.TableHeaderCell>
                                        <S.TableHeaderCell>DATA CAD.</S.TableHeaderCell>
                                        <S.TableHeaderCell>E-MAIL</S.TableHeaderCell>
                                        <S.TableHeaderCell>CELULAR</S.TableHeaderCell>
                                        <S.TableHeaderCell>CLIENTES</S.TableHeaderCell>
                                    </S.TableRow>
                                </S.TableHeader>
                                <S.TableBody>
                                    {consultores && consultores.length > 0 ? (
                                        consultores.map((consultor, index) => (
                                            <S.TableRow onClick={() => setSelected(consultor)} key={index}>
                                                <S.TableCell>{consultor.ID}</S.TableCell>
                                                <S.TableCell>{consultor.NAME}</S.TableCell>
                                                <S.TableCell>{formatDateClients(consultor.DATACRIACAO)}</S.TableCell>
                                                <S.TableCell>{consultor.EMAIL}</S.TableCell>
                                                <S.TableCell>{consultor.CONTACT || 'não informado'}</S.TableCell>
                                                <S.TableCell>{(consultor.CLIENTS) ? consultor.CLIENTS.length : 0}</S.TableCell>
                                            </S.TableRow>
                                        ))
                                    ) : (
                                        <S.TableRow>
                                            <S.TableCell colSpan="6">Nenhum consultor encontrado</S.TableCell>
                                        </S.TableRow>
                                    )}
                                </S.TableBody>
                            </S.Table>
                        </S.TableContainer>

                        <S.PaginationContainer>
                            <S.PaginationButton onClick={firstPage} disabled={currentPage === 1}>
                                Início
                            </S.PaginationButton>
                            <S.PaginationButton onClick={prevPage} disabled={currentPage === 1}>
                                Anterior
                            </S.PaginationButton>

                            <S.PaginationInfo>
                                {indexOfFirstItem} à {Math.min(indexOfLastItem, totalItems)} de {totalItems}
                            </S.PaginationInfo>

                            <S.PaginationButton
                                onClick={nextPage}
                                disabled={currentPage === Math.ceil(totalItems / itemsPerPage) || totalItems === 0}
                            >
                                Próxima
                            </S.PaginationButton>
                            <S.PaginationButton
                                onClick={lastPage}
                                disabled={currentPage === Math.ceil(totalItems / itemsPerPage) || totalItems === 0}
                            >
                                Última
                            </S.PaginationButton>
                        </S.PaginationContainer>
                    </S.ConsultoresTable>
                </S.ConsultoresTableContainer>
            </S.ConsultoresContainer>
        </>
    );
}