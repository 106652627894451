import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SideBar from './Components/SideBar/SideBar';
import Home from './Components/Home/Home';
import Clients from './Components/Clients/Clients';
import Users from './Components/Users/Users';
import Contratos from './Components/Contratos/Contratos';
import Depositos from './Components/Depositos/Depositos';
import Login from './Components/Login';
import { useEffect, useState } from 'react';
import Saques from './Components/Saques/Saques';
import Validacao from './Components/ValidacaoDocumentos/Validacao';
import CreateNews from './Components/CreateNews';
import Rendimento from './Components/Rendimento/Rendimentos';
import SaquesFeitos from './Components/SaquesFeitos/SaquesFeitos';
import HomeFuncoes from './Components/Funcoes/Home/Home';
import HomeController from './Components/ControladorPlataforma.js/Home';
import CriarMensagem from './Components/CriarMensagem/CriarMensagem';
import Indicacao from './Components/Indicacao/Indicacao';
import AnteciparLucro from './Components/AnteciparLucro/AnteciparLucro';
import PlusAnual from './Components/PlusAnual/PlusAnual';
import PlusAnual2 from './Components/PlusAnual2/PlusAnual';
import { createGlobalStyle } from 'styled-components';
import RedefinirEmail from './Components/RedefinirConta/RedefinirEmail';
import Loading from './Components/Loader';
import Relatar from './Components/Relatar/Relatar';
import Relatos from './Components/Relatos/Relatos';
import { AuthContext } from './Context/AuthContext'; // Importe o AuthContext
import { useContext } from 'react';
import ExtrairDados from './Components/ExtrairDados/ExtrairDados';
import Acessos from './Components/Acessos/Acessos';
import Consultores from './Components/Consultores/Consultores';

const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #d7dbe0, #c5cdd6, #b3bec9);
    border-radius: 0px;
    // border: 1px solid white;
  }
`;

const NAV_LINKS = [
  { name: 'Home', path: '/' },
  { name: 'Usuários', path: '/usuarios' },
  { name: 'Clientes', path: '/clientes' },
  { name: 'Consultores', path: '/consultores' },
  { name: 'Contratos', path: '/contratos' },
  { name: 'Validar Contrato', path: '/depositos' },
  { name: 'Validar Saques', path: '/saques' },
  { name: 'Saques Feitos', path: '/saquesFeitos' },
  { name: 'Indicação', path: '/indicacao' },
  { name: 'Notícias', path: '/noticias' },
  { name: 'Mensagens', path: '/mensagem' },
  // { name: 'Rendimentos', path: '/rendimentos' },
  { name: 'Controlador', path: '/controller' },
  { name: 'Funções', path: '/funcoes' },
  { name: 'Extrair Dados', path: '/extract' },
  { name: 'Suporte', path: '/report' },
];

export default function App() {
  const { authState } = useContext(AuthContext); // Acesse o AuthContext
  const [load, setLoad] = useState(false);

  return (
    <Router>
      <GlobalStyle />
      <Loading load={load} />
      <div className="App">
        {authState?.isAuthenticated ? ( 
          <>
            <SideBar NAV_LINKS={NAV_LINKS} />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/clientes" element={<Clients />} />
              <Route path="/contratos" element={<Contratos />} />
              <Route path="/depositos" element={<Depositos />} />
              <Route path="/usuarios" element={<Users />} />
              <Route path="/saques" element={<Saques />} />
              <Route path="/saquesFeitos" element={<SaquesFeitos />} />
              <Route path="/indicacao" element={<Indicacao />} />
              <Route path="/noticias" element={<CreateNews />} />
              <Route path="/mensagem" element={<CriarMensagem />} />
              <Route path="/rendimentos" element={<Rendimento />} />
              <Route path="/controller" element={<HomeController />} /> 
              <Route path="/funcoes" element={<HomeFuncoes />} />
              <Route path="/report" element={<Relatar />} />
              <Route path="/devreport" element={<Relatos />} />
              <Route path="/redefiniremail" element={<RedefinirEmail />} />
              <Route path="/plusAnual" element={<PlusAnual2 />} />
              <Route path="/anteciparLucro" element={<AnteciparLucro />} />
              <Route path="/extract" element={<ExtrairDados />} />
              <Route path="/access" element={<Acessos />} />
              <Route path="/consultores" element={<Consultores />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}
