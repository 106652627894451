import axios from 'axios';
import React, { createContext, useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, getDoc, doc, updateDoc, setDoc } from 'firebase/firestore';
import { db, auth } from '../DATABASE/firebaseConfig';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        user: null,
        isAuthenticated: false,
        accessToken: null,
        permission: ""
    });
    const [clientsDataBase, setClientsDatabase] = useState([]);
    const [totalClients, setTotalClients] = useState(0);
    const [totalClientsWithMoney, setTotalClientsWithMoney] = useState(0);
    const [purchases, setPurchases] = useState([]);
    const [purchasesForWithdraw, setPurchasesForWithdraw] = useState([]);
    const [totalPurchases, setTotalPurchases] = useState(0);
    const [pendingPurchases, setPendingPurchases] = useState([]);
    const [totalPendingPurchases, setTotalPendingPurchases] = useState(0);
    const [withdrawals, setWithdrawals] = useState([]);
    const [totalWithdrawals, setTotalWithdrawals] = useState(0);
    const [withdrawalsPending, setWithdrawalsPending] = useState([]);
    const [totalWithdrawalsPending, setTotalWithdrawalsPending] = useState(0);
    const [clientsForSelect, setClientsForSelect] = useState([]);
    const [purchaseForSelect, setPurchaseForSelect] = useState(null);
    const [access, setAccess] = useState([]);
    const [totalAccess, setTotalAccess] = useState(0);

    // useEffect(() => {
    //     const unsubscribe = onAuthStateChanged(auth, (user) => {
    //         if (user) {
    //             setAuthState({
    //                 user: user,
    //                 isAuthenticated: true,
    //                 accessToken: user.accessToken
    //             });
    //         } else {
    //             setAuthState({
    //                 user: null,
    //                 isAuthenticated: false,
    //                 accessToken: null,
    //                 permission: null
    //             });
    //         }
    //     });

    //     return () => unsubscribe();
    // }, []);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const token = await user.getIdToken();
                    // Buscar novamente as permissões ao recarregar
                    const adminCollectionRef = collection(db, 'ADMIN');
                    const q = query(adminCollectionRef, where('EMAIL', '==', user.email));
                    const querySnapshot = await getDocs(q);

                    if (!querySnapshot.empty) {
                        const adminDoc = querySnapshot.docs[0];
                        const adminData = adminDoc.data();

                        setAuthState({
                            user: user,
                            isAuthenticated: true,
                            accessToken: token,
                            permission: adminData.PERMISSION || ""
                        });
                    }
                } catch (error) {
                    console.error("Erro ao buscar permissões:", error);
                }
            } else {
                setAuthState({
                    user: null,
                    isAuthenticated: false,
                    accessToken: null,
                    permission: ""
                });
            }
        });

        return () => unsubscribe();
    }, []);

    const login = async (email, password) => {
        try {
            const adminCollectionRef = collection(db, 'ADMIN');
            const q = query(adminCollectionRef, where('EMAIL', '==', email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userCredential = await signInWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;
                const token = await user.getIdToken();

                const adminDoc = querySnapshot.docs[0];
                const adminData = adminDoc.data();

                setAuthState({
                    user: user,
                    isAuthenticated: true,
                    accessToken: token,
                    permission: adminData.PERMISSION || ""
                })
            } else {
                console.error('Email não encontrado na coleção ADMIN.');
                alert("Email Inválido.");
            }
        } catch (error) {
            console.error('Erro no processo de login:', error);
            alert("Login Inválido.");
        }
    };

    const logout = async () => {
        try {
            await signOut(auth);
            setAuthState({ user: null, isAuthenticated: false });
            localStorage.removeItem('token');
            window.location.href = "/";
        } catch (error) {
            console.error('Erro ao sair:', error);
        }
    };


    const getClients = async (filter, offset, strFilter, orderFilter, bestClients) => {
        var padraoFilter = 20;
        var padraoOffset = 0;

        if (filter > 0 && offset >= 0) {
            padraoFilter = filter;
            padraoOffset = offset;
        }

        const url = `${process.env.REACT_APP_API_BASE_URL}/getAllClientesWithPlusInfoFilter?` +
            `limit=${padraoFilter}&offset=${padraoOffset}` +
            (strFilter ? `&filtroString=${encodeURIComponent(strFilter)}` : '') +
            `&order=${encodeURIComponent(orderFilter)}` +
            (bestClients && bestClients.trim() === "true" ? `&bestClients=true` : "");

        const headers = {
            headers: {
                Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`
            }
        }

        await axios.get(url, headers)
            .then(res => {
                const cls = res.data.data;

                setClientsDatabase(cls);

                setTotalClients(res.data.total);
                setTotalClientsWithMoney(res.data.totalWithMoney);
                console.log(res.data)
                return res.data;
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.status === 403)
                    logout();
            });
    }

    const getAccess = async (filter, offset, strFilter, orderFilter) => {
        var padraoFilter = 20;
        var padraoOffset = 0;

        if (filter > 0 && offset >= 0) {
            padraoFilter = filter;
            padraoOffset = offset;
        }


        const url = `${process.env.REACT_APP_API_BASE_URL}/getAccess?` +
            `limit=${padraoFilter}&offset=${padraoOffset}` +
            (strFilter ? `&filtroString=${encodeURIComponent(strFilter)}` : '') +
            `&order=${encodeURIComponent(orderFilter)}`;

        const headers = {
            headers: {
                Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`
            }
        }

        await axios.get(url, headers)
            .then(res => {
                const cls = res.data.data;
                console.log(cls)
                setAccess(cls);
                setTotalAccess(res.data.total);
                return res.data;
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.status === 403)
                    logout();
            });
    }

    const getClientsForSelect = async (filter, offset, strFilter, orderFilter, bestClients) => {
        var padraoFilter = 50;
        var padraoOffset = 0;

        if (filter > 0 && offset >= 0) {
            padraoFilter = filter;
            padraoOffset = offset;
        }

        const headers = {
            headers: {
                Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`
            }
        }

        const url = `${process.env.REACT_APP_API_BASE_URL}/getAllClientesWithPlusInfoFilter?` +
            `limit=${padraoFilter}&offset=${padraoOffset}` +
            (strFilter ? `&filtroString=${encodeURIComponent(strFilter)}` : '') +
            `&order=${encodeURIComponent(orderFilter)}` +
            (bestClients && bestClients.trim() === "true" ? `&bestClients=true` : "");

        await axios.get(url, headers)
            .then(res => {
                setClientsForSelect(res.data.data)
                return true;
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.status === 403)
                    logout();
                return false;
            });
    }

    const getPurchases = async (filter, offset, orderFilter, status, search) => {
        var padraoFilter = 20;
        var padraoOffset = 0;

        console.log("status")
        console.log(status)

        if (filter > 0 && offset >= 0) {
            padraoFilter = filter;
            padraoOffset = offset;
        }

        const headers = {
            headers: {
                Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`
            }
        }

        const url = `${process.env.REACT_APP_API_BASE_URL}/obterDepositosFilter?` +
            `limit=${padraoFilter}&offset=${padraoOffset}` +
            (orderFilter ? `&order=${orderFilter}` : '') +
            (status ? `&status=${(status)}` : '') +
            (search && search.trim() != "" ? `&filtroString=${search}` : "");
        await axios.get(url, headers)
            .then(res => {
                const cls = res.data.data;
                setTotalPurchases(res.data.total)
                setPurchases(cls);
                return res.data;
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.status === 403)
                    logout();
            });
    }

    const getPurchasesToWithdraw = async (filter, offset, orderFilter, status, search) => {
        var padraoFilter = 20;
        var padraoOffset = 0;

        console.log("status")
        console.log(status)

        if (filter > 0 && offset >= 0) {
            padraoFilter = filter;
            padraoOffset = offset;
        }

        const headers = {
            headers: {
                Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`
            }
        }

        const url = `${process.env.REACT_APP_API_BASE_URL}/obterDepositosFilter?` +
            `limit=${padraoFilter}&offset=${padraoOffset}` +
            (orderFilter ? `&order=${orderFilter}` : '') +
            (search && search.trim() != "" ? `&filtroString=${search}` : "");
        await axios.get(url, headers)
            .then(res => {
                const cls = res.data.data;
                setPurchasesForWithdraw(cls);
                return res.data;
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.status === 403)
                    logout();
            });
    }

    const getPurchaseById = async (clientId, contratoId) => {

        const url = `${process.env.REACT_APP_API_BASE_URL}/obterDepositoById?contratoId=${contratoId}&userId=${clientId}`;
        const headers = {
            headers: {
                Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`
            }
        }
        await axios.get(url, headers)
            .then(res => {
                if (res.data)
                    setPurchaseForSelect(res.data)
                return res;
            })
            .catch(err => {
                console.log(err);
                if (err && err.response.status === 401 || err.response.status === 403)
                    logout();
                else if (err && err.response.status === 404) {
                    alert(`Contrato #${contratoId} não encontrado`);
                    setPurchaseForSelect(null)

                }
                return err;
            });
    }

    const getPendingPurchases = async (filter, offset, orderFilter, search) => {
        var padraoFilter = 20;
        var padraoOffset = 0;

        if (filter > 0 && offset >= 0) {
            padraoFilter = filter;
            padraoOffset = offset;
        }

        const headers = {
            headers: {
                Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`
            }
        }

        const url = `${process.env.REACT_APP_API_BASE_URL}/obterDepositosFilter?` +
            `limit=${padraoFilter}&offset=${padraoOffset}` +
            `&order=desc` +
            `&status=4` +
            (search.trim() != `` ? `&filtroString=${search}` : "");
        await axios.get(url, headers)
            .then(res => {
                const cls = res.data.data;
                setTotalPendingPurchases(res.data.total)
                setPendingPurchases(cls);
                return res.data;
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401)
                    logout();
            });
    }

    const getSaques = async (filter, offset, orderFilter, search) => {
        var padraoFilter = 20;
        var padraoOffset = 0;

        if (filter > 0 && offset >= 0) {
            padraoFilter = filter;
            padraoOffset = offset;
        }

        const headers = {
            headers: {
                Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`
            }
        }

        const url = `${process.env.REACT_APP_API_BASE_URL}/obterSaquesFilter?` +
            `limit=${padraoFilter}&offset=${padraoOffset}` +
            (orderFilter ? `&order=${orderFilter}` : '') +
            `&status=0` +
            (search.trim() != `` ? `&filtroString=${search}` : "");
        await axios.get(url, headers)
            .then(res => {
                const cls = res.data.data;
                setTotalWithdrawals(res.data.total)
                setWithdrawals(cls);
                return res.data;
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.status === 403)
                    logout()
            });
    }

    const getSaquesPending = async (filter, offset, orderFilter, search) => {
        var padraoFilter = 20;
        var padraoOffset = 0;

        if (filter > 0 && offset >= 0) {
            padraoFilter = filter;
            padraoOffset = offset;
        }

        const headers = {
            headers: {
                Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`
            }
        }

        const url = `${process.env.REACT_APP_API_BASE_URL}/obterSaquesFilter?` +
            `limit=${padraoFilter}&offset=${padraoOffset}` +
            `&order=${orderFilter}` +
            `&status=1` +
            (search.trim() != `` ? `&filtroString=${search}` : "");
        await axios.get(url, headers)
            .then(res => {
                const cls = res.data.data;
                setTotalWithdrawalsPending(res.data.total)
                setWithdrawalsPending(cls);
                return res.data;
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.status === 403)
                    logout();
            });
    }

    const handleUnselectPurchaseForSelect = () => {
        setPurchaseForSelect(null);
    }

    const downloadExcel = async (orderFilter, status, search, type, monthFilter, yearFilter, bestClientsOrder, consultorId) => {
        var padraoFilter = 10000;
        var padraoOffset = 0;

        const headers = {
            headers: {
                Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`,
            },
            responseType: 'blob',
        };

        const url = `${process.env.REACT_APP_API_BASE_URL}/extractData?` +
            `limit=${padraoFilter}&offset=${padraoOffset}` +
            (orderFilter ? `&order=${orderFilter}` : '') +
            (status ? `&status=${status}` : '') +
            (search && search.trim() !== "" ? `&filtroString=${search}` : "") +
            (type ? `&type=${type === "dinheiroinvestido" ? "clientes" : type}` : "") +
            ((monthFilter && monthFilter.trim() != "") ? `&monthFilter=${monthFilter}` : "") +
            ((yearFilter && yearFilter.trim() != "") ? `&yearFilter=${yearFilter}` : "") +
            ((bestClientsOrder && bestClientsOrder.trim() != "") ? `&bestClients=${bestClientsOrder}` : "") +
            ((consultorId && consultorId.trim() != "") ? `&consultorId=${consultorId}` : "");

        try {
            const response = await axios.get(url, headers);

            if (response.data) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', `${type}-data.xlsx`);
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);

                console.log("Download Concluído");
            } else {
                console.log("Nenhum dado recebido para download.");
            }
        } catch (err) {
            console.log("Erro Ao Fazer Download", err);
            if (err.response.status === 401 || err.response.status === 403) {
                logout();
            }
        }
    };

    return (
        <AuthContext.Provider value={{
            authState, login, logout, getClients, clientsDataBase, totalClients, totalClientsWithMoney,
            getPurchases, purchases, totalPurchases, getPendingPurchases, pendingPurchases, totalPendingPurchases,
            withdrawals, totalWithdrawals, getSaques, getSaquesPending, withdrawalsPending, totalWithdrawalsPending,
            getClientsForSelect, clientsForSelect, getPurchaseById, purchaseForSelect, handleUnselectPurchaseForSelect,
            downloadExcel, getAccess, access, totalAccess, getPurchasesToWithdraw, purchasesForWithdraw
        }}>
            {children}
        </AuthContext.Provider>
    );
};