import React, { useState, useEffect, useContext } from "react";
import * as S from "./ModalConsultorStyle";
import axios from "axios";
import { AuthContext } from "../../../Context/AuthContext";
import Loading from "../../Loader";

export default function ModalConsultor({ onClose, client }) {
    const { authState } = useContext(AuthContext);
    const [stringFilter, setStringFilter] = useState("");
    const [consultores, setConsultores] = useState([]);
    const [load, setLoad] = useState(false);
    const [itemsPerPage] = useState(10);

    const getConsultores = async (page = 1, order = "asc") => {
        setLoad(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_OBTER_CONSULTOR}`,
                {
                    params: {
                        page,
                        limit: 1000,
                        filterString: stringFilter,
                        sortOrder: order
                    }
                }
            );

            if (response.status === 200) {
                setConsultores(response.data.consultores);
            }
        } catch (err) {
            console.error("Erro ao buscar consultores:", err);
            alert("Erro ao obter consultores");
        } finally {
            setLoad(false);
        }
    }

    useEffect(() => {
        getConsultores();
    }, []);

    const handleAssociate = async (consultor) => {
        const consultorId = consultor.ID;

        if (!consultorId) {
            return alert("ID do consultor é necessário.");
        }

        if (!window.confirm(`Prosseguir a associação do consultor ${consultor.NAME} ao cliente ${client.NAME}?`)) {
            return;
        }

        setLoad(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ADICIONAR_CLIENTE_CONSULTOR}`, {
                consultorId,
                cliente: {
                    CPF: client.CPF,
                    NAME: client.NAME
                }
            },
                {
                    headers: {
                        Authorization: `Bearer ${authState.accessToken}`
                    }
                }
            );

            if (response.status === 200) {
                alert("Consultor associado com sucesso!");
                onClose();
            }
        } catch (error) {
            console.log(error)
            alert("Erro ao associar consultor")
        } finally {
            setLoad(false);
        }
    };

    return (
        <>
            <Loading load={setLoad} />
            <S.ModalContainer>
                <S.ModalCenter>
                    <span onClick={onClose} className="voltar">&lt; Voltar</span>
                    <S.Header>
                        <span className="titulo">Consultores</span>
                        <div className="search">
                            <input value={stringFilter} onChange={(e) => setStringFilter(e.target.value.toUpperCase())} placeholder="Pesquise pelo nome" />
                            <button onClick={() => getConsultores()}>Pesquisar</button>
                        </div>
                    </S.Header>
                    <S.Body>
                        <span className="explain">Clique no consultor que deseja associar.</span>
                        <S.List>
                            <div className="rowColumns">
                                <span className="item" style={{ borderRight: "2px solid black" }}>Id</span>
                                <span className="item" style={{ borderRight: "2px solid black" }}>Nome</span>
                                <span className="item">Email</span>
                            </div>
                            <div className="rowsContainer">
                                {consultores && consultores.length > 0 ? (
                                    <>
                                        {consultores.map((consultor, index) => (
                                            <div
                                                className="row"
                                                key={index}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleAssociate(consultor);
                                                }}
                                                style={{ cursor: "pointer" }} // Adiciona feedback visual
                                            >
                                                <span className="item" style={{ borderRight: "2px solid black", borderLeft: "2px solid black" }}>{consultor.ID}</span>
                                                <span className="item" style={{ borderRight: "2px solid black" }}>{consultor.NAME}</span>
                                                <span className="item" style={{ borderRight: "2px solid black" }}>{consultor.EMAIL}</span>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <div>Nenhum consultor encontrado</div>
                                )}
                            </div>
                        </S.List>
                    </S.Body>
                </S.ModalCenter>
            </S.ModalContainer>
        </>
    )
}