import React, { useState, useEffect } from "react";
import * as S from './MinContratosStyle';
import { db } from "../../../../DATABASE/firebaseConfig";
import { getDoc, doc, updateDoc } from "firebase/firestore";

export default function MinContratos() {
    const [valorAtual, setValorAtual] = useState("")
    const [valor, setValor] = useState("")
    const [showValorButton, setShowValorButton] = useState(false);

    const fetchValorAtualMoeda = async () => {
        try {
            const docRef = doc(db, 'SYSTEM_VARIABLES', 'MIN_VALUE');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                if (data) {
                    setValorAtual(data.MIN_VALUE)
                }
            } else {
                console.log('Documento não encontrado.');
            }
        } catch (error) {
            console.error('Erro ao buscar pelo valor unitário do contrato', error);
        }
    };

    const handleValorInputChange = (event) => {
        const newValue = event.target.value;
        setValor(newValue)
        setShowValorButton(true)
    };

    const handleRedefinirClick = async () => {
        try {
            const docRef = doc(db, 'SYSTEM_VARIABLES', 'MIN_VALUE');

            await updateDoc(docRef, {
                MIN_VALUE: parseFloat(valor)
            });

            setValorAtual(valor);
            setValor("")
            setShowValorButton(false);

            alert('Alterado com sucesso');
        } catch (error) {
            console.error('Erro ao redefinir valores', error);
        }
    };

    useEffect(() => {
        fetchValorAtualMoeda();
    }, []);

    return (
        <S.ValorUniContratoContainer>
            <S.ContainerTitle>CONFIGURAÇÕES DE COMPRA MÍNIMA</S.ContainerTitle>
            <S.ContainerDivisor>
                <S.UniContratoValue>
                    <S.BoxTitle><span>MÍNIMO ATUAL:</span></S.BoxTitle>
                    <S.StatusMessage>
                        {valorAtual
                            ? `A quantidade mínima atual é ${valorAtual}`
                            : 'Valor unitário não encontrado'}
                    </S.StatusMessage>

                    <S.RedefineValorBox>
                        <h3>O novo mínimo será: </h3>
                        <input
                            type="number"
                            placeholder=" > 0"
                            value={valor}
                            onChange={handleValorInputChange}
                        />
                    </S.RedefineValorBox>

                    {showValorButton && (
                        <S.RedefineValorButton>
                            <button onClick={handleRedefinirClick}> REDEFINIR VALOR </button>
                        </S.RedefineValorButton>
                    )}
                </S.UniContratoValue>
            </S.ContainerDivisor>
        </S.ValorUniContratoContainer>
    );
}
