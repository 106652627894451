import React, { useState, useEffect, useContext } from "react";
import * as S from "./ModalCriarStyle";
import axios from "axios";
import { AuthContext } from "../../../Context/AuthContext";
import Loading from "../../Loader";


export default function ModalCriar({ onClose }) {
    const { authState } = useContext(AuthContext);
    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [load, setLoad] = useState(false);

    const handleClose = () => {
        setName("");
        setId("");
        setEmail("");
        setContact("");
        onClose();
    }

    const handleConfirm = async () => {
        if (name.trim() === "" || id.trim() === "" || email.trim() === "" || contact.trim() === "")
            return alert("Adicione todas as informações.");

        setLoad(true);

        try {
            axios.post(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_CRIAR_CONSULTOR}`, {
                NAME: name,
                ID: id,
                EMAIL: email,
                CONTACT: contact
            }, {
                headers: { Authorization: `Bearer ${authState.accessToken}` }
            }).then(res => {
                if (res.status === 201) {
                    alert("Consultor criado com sucesso.");
                    handleClose();
                    setLoad(false);
                    return;
                }
            }).catch(err => {
                setLoad(false);
                alert(`Houve um erro ao tentar criar o consultor`);
                console.log(err);
                return;
            })
        } catch (error) {
            setLoad(false);
            alert(`Houve um erro ao tentar criar o consultor`);
            console.log(error);
            return;
        }
    }

    return (
        <>
            <Loading load={load} />
            <S.CriarContainer>
                <S.Voltar onClick={handleClose}> &lt;  Voltar</S.Voltar>
                <S.ModalContent>
                    <div className="headerContent">
                        <span className="title">Cadastrar Consultor</span>
                        <span className="subtitle">Adicione todas as informações abaixo.</span>
                    </div>

                    <S.PrencherInformacoes>
                        <S.BoxInformacao>
                            <span className="name">NOME</span>
                            <input value={name} onChange={(e) => setName(e.target.value.toUpperCase())} className="inputValue" placeholder="Nome Completo" />
                        </S.BoxInformacao>
                        <S.BoxInformacao>
                            <span className="name">ID</span>
                            <input value={id} onChange={(e) => setId(e.target.value)} className="inputValue" placeholder="CPF, ID...." />
                        </S.BoxInformacao>
                        <S.BoxInformacao>
                            <span className="name">EMAIL</span>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} className="inputValue" placeholder="" />
                        </S.BoxInformacao>
                        <S.BoxInformacao>
                            <span className="name">CONTATO</span>
                            <input value={contact} onChange={(e) => setContact(e.target.value)} className="inputValue" placeholder="DDD + Número" />
                        </S.BoxInformacao>
                    </S.PrencherInformacoes>
                    <S.ModalButton>
                        <button onClick={handleConfirm}>Confirmar</button>
                    </S.ModalButton>
                </S.ModalContent>
            </S.CriarContainer>
        </>
    )
}