import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { storage, db } from "../DATABASE/firebaseConfig";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { collection, addDoc, getDocs, doc, deleteDoc } from "firebase/firestore";
import { AuthContext } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";

function ModalComponent({ onClose }) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [textAlign, setTextAlign] = useState('left');
    const [fileUploadProgress, setFileUploadProgress] = useState(0);
    const [imageUrl, setImageUrl] = useState(null);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleTextAlignChange = (align) => {
        setTextAlign(align);
    };

    const handleUpload = () => {
        if (selectedImage) {
            const randomName = Math.random().toString(36).substring(7);
            const imageRef = ref(storage, `news/${randomName}`);

            uploadString(imageRef, selectedImage, 'data_url').then((snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setFileUploadProgress(progress);

                return getDownloadURL(imageRef);
            })
                .then((url) => {
                    saveNewsData(url); // Salva os dados da notícia após obter a URL da imagem
                    setImageUrl(url); // Salva a URL da imagem no estado
                    alert("Imagem enviada com sucesso!"); // Alerta de sucesso
                })
                .catch((error) => {
                    console.error("Erro ao fazer upload da imagem:", error);
                    alert("Erro ao enviar a imagem. Tente novamente."); // Alerta de erro
                });
        }
    };

    const saveNewsData = (imageUrl) => {
        const date = new Date();
        const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;

        addDoc(collection(db, 'NEWS'), {
            title: title,
            body: body,
            imageUrl: imageUrl,
            data: formattedDate
        })
            .then((docRef) => {
                console.log("Documento adicionado com ID: ", docRef.id);
                alert("Notícia criada com sucesso!"); // Alerta de sucesso
                onClose(); // Fecha o modal após salvar os dados
            })
            .catch((error) => {
                console.error("Erro ao adicionar documento: ", error);
                alert("Erro ao criar a notícia. Tente novamente."); // Alerta de erro
            });
    };



    return (
        <ModalComponentContainer id="modal-content">
            <ModalContent>
                <CloseButton onClick={onClose}>X</CloseButton>
                <h6>CRIE UMA NOTÍCIA</h6>

                <NewsBox>
                    <PhotoInputBox>
                        <input type="file" onChange={handleImageChange} />
                        {selectedImage && <img src={selectedImage} alt="Selected" />}
                    </PhotoInputBox>

                    <NewsInfo>
                        <NewsTitle id="news-title">
                            <span>TÍTULO</span>
                            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </NewsTitle>

                        <NewsBody id="news-body">
                            <span>BODY</span>
                            <textarea style={{ textAlign: textAlign }} value={body} onChange={(e) => setBody(e.target.value)} />
                            {/* <h4>SELECIONE O ALINHAMENTO</h4> */}
                            {/* <div>
                                <button className="left" onClick={() => handleTextAlignChange('left')}>À ESQUERDA</button>
                                <button className="center" onClick={() => handleTextAlignChange('center')}>CENTRO</button>
                                <button className="justified" onClick={() => handleTextAlignChange('justify')}>JUSTIFICADA</button>
                                <button className="right" onClick={() => handleTextAlignChange('right')}>À DIREITA</button>
                            </div> */}
                        </NewsBody>
                    </NewsInfo>

                    <SendNewsBox>
                        <button onClick={handleUpload}>ENVIAR</button>
                        {/* {imageUrl && <button onClick={handleOpenImage}>Abrir Imagem</button>} */}
                    </SendNewsBox>
                </NewsBox>
            </ModalContent>
        </ModalComponentContainer>
    );
}

export default function CreateNews() {
    const { authState } = useContext(AuthContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [news, setNews] = useState([])
    const navigate = useNavigate();

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    // console.log(authState)

    useEffect(() => {
        if (authState.permission != "ALL" && !authState.permission.includes("CRIAR/EXCLUIR NOTÍCIAS")) {
            navigate("/")
        }
    }, [authState])

    const getNews = async () => {
        try {
            const qs = await getDocs(collection(db, "NEWS"));
            const allNews = qs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setNews(allNews);
        } catch (error) {
            console.log(error);
            alert(error.message)
        }
    }

    const deleteNews = async (id) => {
        try {
            await deleteDoc(doc(db, "NEWS", id));
            // Update the news state after deletion
            setNews(news.filter(item => item.id !== id));
            alert("Notícia deletada com sucesso!");
        } catch (error) {
            console.log("Erro ao deletar notícia:", error);
            alert("Erro ao deletar notícia. Tente novamente.");
        }
    };

    useEffect(() => {
        getNews();
    }, []);

    useEffect(() => {
        getNews();
    }, [news]);


    return (
        <CreateNewsContainer>
            <CreateNewsContent>
                <TitleComponent><h1 onClick={handleOpenModal}> &bt; Criar Nova Notícia</h1></TitleComponent>
                {isModalOpen && <ModalComponent onClose={handleCloseModal} />}

                <NoticiasCriadasContainer>
                    <h4>Notícias</h4>
                    <ul >
                        {news.length > 0 && news.map((n => (
                            <li key={n.id}>
                                <div>
                                    <div>
                                        <img alt="imagem da notícia" src={n.imageUrl} />
                                    </div>
                                    <div>
                                        <p>{n.title}</p>
                                    </div>
                                </div>
                                <button onClick={() => { deleteNews(n.id) }}>excluir</button>
                                <span>{n.data}</span>
                            </li>
                        )))}
                    </ul>


                </NoticiasCriadasContainer>
            </CreateNewsContent>
        </CreateNewsContainer>
    );
}

// Seus estilos aqui

const NoticiasCriadasContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    h4{
        color: gold;
        margin: 0;
        font-size: 22px;
        width: 100%;
        border-bottom: 3px solid gold;
        margin-bottom: 20px;
    }

    ul{
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 20px;

        li{
            position: relative;
            background-color: rgba(255, 255, 255, 0.1);
            display: flex;
            align-items: start;
            border-radius: 8px;
            justify-content: center;
            flex-direction: column;
            padding: 40px 5px 5px 5px;

            div{
                display: flex;
                justify-content: start;
                gap: 20px;

                div{
                    
                    img{
                        width: 80px;
                    }

                    p{
                        margin: 0;
                        color: rgba(255, 255, 255, 0.7);
                        text-align: start;
                    }
                }
            }

            span{
                position: absolute;
                top: 5px;
                right: 10px;
                color: rgba(255, 255, 255, 0.5);
            }

            button{
                margin-top: 10px;
                margin-bottom: 10px;
                width: 100px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid transparent;
                background: linear-gradient(to bottom, rgba(255, 20, 40, 1), rgba(255, 120, 40, 1));
                transition: .6s;

                &:hover{
                    background: transparent;
                    border: 2px solid white;

                }
            }
        }
    }

`;

const CreateNewsContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: start;
    position: relative;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);
`;

const CreateNewsContent = styled.div`
    width: 100%;
    height: max-content;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TitleComponent = styled.div`

    margin-top: 40px;
    width: 100%;
    text-align: start;
    color: #FFFFFF;
    box-sizing: border-box;
    overflow: hidden;

    h1 {
        cursor: pointer;
        transition: .3s;
        box-sizing: border-box;
        position: relative;

        &:hover {
            color: rgba(255, 171, 80, 1);
            padding-left: 20px;
        }
    }
    
    @keyframes glow {
        0% {
            text-shadow: 0 0 1px rgba(255, 171, 80, 0.5), 0 0 10px rgba(255, 171, 80, 0.4), 0 0 15px rgba(255, 171, 80, 0.3), 0 0 20px rgba(255, 171, 80, 0.2);
        }
        100% {
            text-shadow: 0 0 20px rgba(255, 171, 80, 1), 0 0 30px rgba(255, 171, 80, 0.7), 0 0 40px rgba(255, 171, 80, 0.6), 0 0 50px rgba(255, 171, 80, 0.5);
        }
    }
`;

const ModalComponentContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 40px;

    @media (max-width: 1200px){
        padding: 0px;
    }
`;

const ModalContent = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 3px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.4);
    background: linear-gradient(to right, #003566, #001D3D, #003566);
    padding: 20px 30px;
    position: relative;
    z-index: 9999999999999;
    box-sizing: border-box;

    h6 {
        font-size: 38px;
        margin: 0;
        color: #FFC300;
    }

    @media (max-width: 1200px){
        height: 100%;
        width: 100%;
        // padding: 10px;

        h6{
            margin-top: 80px;
            font-size: 22px;
        }
    }
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    left: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
`;

const NewsBox = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-top: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PhotoInputBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    background-color: #001D3D;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    transition: .3s;
    border-radius: 8px;

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    &:hover{
        background-color: rgba(255, 195, 0, 1);
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        border-radius: 8px;
    }
`;

const NewsInfo = styled.div`
    margin-top: 30px;
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    gap: 20px;
`;

const NewsTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;

    span{
        font-size: 28px;
        color: white;
        font-weight: 600;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
        margin-bottom: 5px;
    }

    input{
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        text-align: center;
        border: 1px solid rgba(0,0,0,0.1);
        font-size: 26px;
        color: rgba(0,0,0,0.9);
    }
`;

const NewsBody = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span{
        font-size: 28px;

        font-weight: 600;
        color: white;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    }

    textarea{
        box-sizing: border-box;
        width: 100%;
        min-height: 500px;
        height: 40px;
        text-align: start;
        border: 1px solid rgba(0,0,0,0.1);
        font-size: 26px;
        color: rgba(0,0,0,0.9);
    }

    h4{
        margin: 0;
        margin-top: 10px;
        color: white;
        margin-bottom: 10px;
    }

    div{
        display: flex;
        gap: 10px;

        button{
            width: 200px;
            height: 40px;
            cursor: pointer;
            border: 2px solid transparent;
            border-radius: 3px;
            transition: .3s;

            &:hover{
                border: 2px solid black;
                background-color: #001D3D;
                color: white;
            }
        }

        .left, .right{
            background-color:rgba(250, 154, 49, 0.8); 
        }

        .center, .justified{
            background-color: rgba(18, 147, 247, 0.8);
        }

        @media (max-width: 1200px){
            flex-direction: column;
        }

    }
`;

const SendNewsBox = styled.div`
    width: 100%;
    margin-top: 15px;
    padding-bottom: 50px;
    button{
        width: 100%;
        height: 40px;
        background-color: rgba(20, 189, 0, 1);
        color: white;
        font-weight: 600;
        border: 0;
        font-size: 22px;
        cursor:pointer;

        transition: .3s;

        &:hover{
            border: 2px solid black;
            background-color: transparent;
            color: #FFC300;
        }
    }
`;
