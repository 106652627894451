import styled from "styled-components";

export const CriarContainer = styled.div`
    width: 100vw;
    height: 100vh;
    z-index: 99999999;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(240, 240, 240, 1);
    padding: 30px 40px;
    box-sizing: border-box;
    background-image: url('/textura.jpg');
    background-size: cover;
`;

export const ModalContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .headerContent{
        display: flex;
        flex-direction: column;
        align-items: center;

        .title{
            font-size: 48px;
            font-weight: 800;
        }

        .subtitle{
            font-size: 18px;
            font-weight: 500;
            color: rgba(0,0,0,0.6);
        }
    }
`;

export const PrencherInformacoes = styled.div`
    width: 100%;
    margin-top: 40px;
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 40px;
`;

export const BoxInformacao = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .name{
        font-size: 26px;
        color: black;
    }

    .inputValue{
        width: 100%;
        height: 45px;
        border: 1px solid black;
        box-shadow: 2px 2px 1px rgba(0,0,0,0.4);
        box-sizing: border-box;
        padding: 0;
        font-size: 18px;
        padding-left: 30px;
    }
`;

export const ModalButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button{
        width: 100%;
        height: 45px;
        border: 0;
        border-radius: 6px;
        background: rgba(80, 230, 0, 1);
        font-size: 20px;
        transition: .3s;
        cursor: pointer;
        box-shadow: 2px 2px 1px rgba(0,0,0,0.4);
        border: 1px solid black;
    }
`;

export const Voltar = styled.div`
    position: absolute;
    top: 25px;
    left: 30px;
    font-size: 22px;
    font-weight: 800;
    transition: .3s;
    cursor: pointer;

    &:hover{
        transform: scale(0.97);
    }
`;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;

