import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as S from './AnteciparLucroStyle';
import Loading from "../Loader";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext";
import { formatDate, formatNumber } from "../ASSETS/assets";
import { useNavigate } from "react-router-dom";

const base_route = process.env.REACT_APP_API_BASE_URL;
const ANTECIPAR = process.env.REACT_APP_ANTECIPAR_LUCRO;

export default function AnteciparLucro() {
    const { logout, getPurchases, purchaseForSelect, handleUnselectPurchaseForSelect, authState, purchases } = useContext(AuthContext);

    const [searchTerm, setSearchTerm] = useState("");
    const [searchTerm2, setSearchTerm2] = useState("");
    const [selectedContract, setSelectedContract] = useState(null);
    const [aditionalValue, setAditionalalue] = useState("");
    const [loading, setLoading] = useState(false);
    const [descrip, setDescrip] = useState("Antecipação");
    const navigate = useNavigate();

    useEffect(() => {
        if (authState.permission != "ALL" && !authState.permission.includes("ANTECIPAR SALDO")) {
            navigate("/")
        }
    }, [])

    const fetchContract = async () => {
        setLoading(true);
        try {
            await getPurchases(100, 0, "asc", "", searchTerm);
        } catch (e) {
            console.log(e)
        }
        setLoading(false);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchChange2 = (event) => {
        setSearchTerm2(event.target.value);
    };

    const handleSendValue = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${base_route}${ANTECIPAR}`, {
                userId: selectedContract.CLIENT_CPF,
                contractId: selectedContract.IDCOMPRA,
                increasement: aditionalValue,
                descrip: descrip
            }, {
                headers: { Authorization: `Bearer ${authState.accessToken}` }
            });

            if (response.data.status === 200) {
                alert("Saldo antecipado com sucesso");
                setLoading(false);
            }
            else {
                alert(`Erro: ${response.data.data}, status : ${response.data.status}`);
                setLoading(false);
            };

        } catch (error) {
            console.log(error)
            setLoading(false);

            if (error.response.status === 401 || error.response.status === 403) {
                alert("Sessão Expirada.");
                logout();
            } else {
                alert(`Houve um erro: ${error}`);
            }
        }

        setSearchTerm('');
        setSelectedContract(null)
        handleUnselectPurchaseForSelect()
    }

    useEffect(() => {
        if (purchaseForSelect)
            setSelectedContract(purchaseForSelect)
        else
            setSelectedContract(null)

    }, [purchaseForSelect])

    return (
        <S.AnteciparLucroContainer>
            <h1>ANTECIPAÇÃO DE LUCRO</h1>
            <Loading load={loading} />

            <S.ClientSearch>
                <h4>CLIENTE</h4>
                <div className="inputBoxFilter">
                    <input
                        type="text"
                        placeholder="NOME OU CPF"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
            </S.ClientSearch>

            <S.ButtonSearch>
                <button onClick={fetchContract}>Procurar</button>
            </S.ButtonSearch>

            <S.SearchedClients>
                <div className="columns">
                    <span>ID COMPRA</span>
                    <span>CONTRATOS</span>
                    <span>VALOR CONTRATO</span>
                    <span>NOME CLIENTE</span>
                    <span>CPF CLIENTE</span>
                    <span>USUÁRIO CLIENTE</span>
                    <span>DATA DA COMPRA</span>
                    <span>FIM DO CONTRATO</span>
                </div>
                {selectedContract && (
                    <>
                        <div className="rowSelected" style={{ borderTop: "2px solid white", borderBottom: "2px solid white" }}>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.IDCOMPRA}</span>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.COINS}</span>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.TOTALSPENT}</span>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.CLIENT_NAME}</span>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.CLIENT_CPF}</span>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && selectedContract.CLIENT_USERNAME}</span>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && (formatDate(selectedContract.PURCHASEDATE) ? formatDate(selectedContract.PURCHASEDATE) : selectedContract.PURCHASEDATE)}</span>
                            <span style={{ borderRight: "2px solid white" }}>{selectedContract && (formatDate(selectedContract.YIELDTERM) ? formatDate(selectedContract.YIELDTERM) : selectedContract.YIELDTERM)}</span>

                            <span onClick={() => setSelectedContract(null)} style={{ color: "red" }}>x</span>
                        </div>
                    </>
                )}
                {selectedContract === null && (
                    <div className="tabelona">
                        {purchases.map((contract, index) => (
                            <div onClick={() => setSelectedContract(contract)} className="row" style={{ borderTop: "2px solid white", borderBottom: "2px solid white" }}>
                                <span style={{ borderRight: "2px solid white" }}>{contract && contract.IDCOMPRA}</span>
                                <span style={{ borderRight: "2px solid white" }}>{contract && contract.COINS}</span>
                                <span style={{ borderRight: "2px solid white" }}>{contract && contract.TOTALSPENT && ("R$" + formatNumber(contract.TOTALSPENT))}</span>
                                <span style={{ borderRight: "2px solid white" }}>{contract && contract.CLIENT_NAME}</span>
                                <span style={{ borderRight: "2px solid white" }}>{contract && contract.CLIENT_CPF}</span>
                                <span style={{ borderRight: "2px solid white" }}>{contract && contract.CLIENT_USERNAME}</span>
                                <span style={{ borderRight: "2px solid white" }}>{contract && (formatDate(contract.PURCHASEDATE) ? formatDate(contract.PURCHASEDATE) : contract.PURCHASEDATE)}</span>
                                <span style={{ borderRight: "2px solid white" }}>{contract && (formatDate(contract.YIELDTERM) ? formatDate(contract.YIELDTERM) : contract.YIELDTERM)}</span>
                            </div>
                        ))}
                    </div>
                )}

            </S.SearchedClients>

            {selectedContract && (
                <>
                    <h4>Encontrado: #{selectedContract.IDCOMPRA} DE {selectedContract.CLIENT_NAME}</h4>
                    <S.QuandoDeseja>
                        <span>QUANTO DESEJA ANTECIPAR? (R$)</span>
                        <input
                            type="number"
                            value={aditionalValue}
                            onChange={(e) => setAditionalalue(e.target.value)}
                        />

                        <span>DESCRIÇÃO (Opcional)</span>
                        <input
                            type="text"
                            value={descrip}
                            onChange={(e) => setDescrip(e.target.value)}
                        />
                        <button onClick={handleSendValue}>ADICIONAR</button>
                    </S.QuandoDeseja>
                </>
            )}

        </S.AnteciparLucroContainer>
    )
}