import React, { useState, useContext, useEffect } from "react";
import * as S from './IndicacaoStyle';
import Loading from "../Loader";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

const base_route = process.env.REACT_APP_API_BASE_URL;
const ADD_INDICACAO = process.env.REACT_APP_ADICIONAR_INDICACAO_GOLDEN;

export default function Indicacao() {
    const { getClientsForSelect, clientsForSelect, authState } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedClient, setSelectedClient] = useState(null);
    const [indicationAddValue, setIndicationAddValue] = useState("");
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        if ( authState.permission != "ALL" && !authState.permission.includes("INDICAÇÃO")) {
            navigate("/")
        }
    }, [])

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectClient = (client) => {
        setSelectedClient(client);
        setSearchTerm("");
    };

    const handleUnSelectClient = () => {
        setSelectedClient(null);
    };

    const fetchClients = async () => {

        setLoading(true);
        try {
            await getClientsForSelect(50, 0, searchTerm, "crescente", "");
        } catch (e) {
            console.log(e)
        }
        setLoading(false);
    };

    const headers = {
        headers: {
            Authorization: `Bearer ${authState.accessToken ? authState.accessToken : ""}`
        }
    }

    const handleSendIndicacao = async () => {
        if (!indicationAddValue || isNaN(indicationAddValue)) {
            alert("Por favor, insira um valor válido.");
            return;
        }

        setLoading(true)

        try {
            const response = await axios.post(`${base_route}${ADD_INDICACAO}`, {
                userId: selectedClient.CPF,
                indicationQtt: parseFloat(indicationAddValue)
            }, headers);

            if (response.data.status === 200) {
                alert("SALDO ADICIONADO COM SUCESSO");
                setIndicationAddValue(""); // Limpa o valor de indicação após sucesso
                setSelectedClient(null); // Limpa a seleção do cliente
                setLoading(false)
            } else {
                alert(`Erro ${response.data.status}, resposta: ${response.data.data}`);
                setLoading(false)

            }
        } catch (error) {
            alert(`Erro ao adicionar indicação: ${error.message}`);
            setLoading(false)

        }
    };

    return (
        <S.IndicacaoContainer>
            <Loading load={loading} />
            <h1>ADICIONAR INDICAÇÃO</h1>

            <S.ClientSearch>
                <h4>PESQUISE PELO CLIENTE</h4>
                <div className="inputBoxFilter">
                    <input
                        type="text"
                        placeholder="CPF OU NOME"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <button onClick={() => fetchClients()}>PESQUISAR</button>
                </div>

                <S.SearchArea>
                    {clientsForSelect && clientsForSelect.length > 0 && (
                        <S.SearchedClients>
                            <div className="columns">
                                <span>NOME</span>
                                <span>CPF</span>
                                <span>EMAIL</span>
                            </div>
                            {selectedClient && (
                                <>
                                    <div className="rowSelected" style={{ borderTop: "2px solid white", borderBottom: "2px solid white" }}>
                                        <span style={{ borderRight: "2px solid white" }}>{selectedClient && selectedClient.NAME}</span>
                                        <span style={{ borderRight: "2px solid white" }}>{selectedClient && selectedClient.CPF}</span>
                                        <span style={{ borderRight: "2px solid white" }}>{selectedClient && selectedClient.EMAIL}</span>
                                        <span onClick={() => handleUnSelectClient()} style={{ color: "red" }}>x</span>
                                    </div>
                                </>
                            )}
                            {selectedClient === null && (
                                <div className="tabelona">
                                    {clientsForSelect.map((client, index) => (
                                        <div onClick={() => handleSelectClient(client)} className="row" style={{ borderTop: "2px solid white", borderBottom: "2px solid white" }}>
                                            <span style={{ borderRight: "2px solid white" }}>{client && client.NAME}</span>
                                            <span style={{ borderRight: "2px solid white" }}>{client && client.CPF}</span>
                                            <span>{client && client.EMAIL}</span>
                                        </div>
                                    ))}
                                </div>
                            )}

                        </S.SearchedClients>
                    )}
                </S.SearchArea>

            </S.ClientSearch>

            {selectedClient && (
                <>
                    <h4>Selecionado: {selectedClient.NAME}</h4>
                    <S.QuandoDeseja>
                        <span>QUANTO DESEJA ADICIONAR AO SALDO DE INDICAÇÃO? (R$)</span>
                        <input
                            type="number"
                            value={indicationAddValue}
                            onChange={(e) => setIndicationAddValue(e.target.value)}
                        />
                        <button onClick={handleSendIndicacao}>ADICIONAR</button>
                    </S.QuandoDeseja>
                </>
            )}
        </S.IndicacaoContainer>
    );
}
