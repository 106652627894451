// CriarMensagem.js
import React, { useContext, useEffect, useState } from "react";
import * as S from './CriarMensagemStyle';
import MensagemBox from "./Componentes/MensagemBox";
import VerMensagens from "./Componentes/VerMensagens";
import EnviarMensagem from "./Componentes/EnviarMensagem";
import Envios from "./Componentes/Envios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";

export default function CriarMensagem() {
    const { authState } = useContext(AuthContext);
    const [isModalOpenCriar, setIsModalOpenCriar] = useState(false);
    const [isModalOpenVer, setIsModalOpenVer] = useState(false);
    const [isModalOpenEnviar, setIsModalOpenEnviar] = useState(false);
    const [isModalOpenVerEnvios, setIsModalOpenVerEnvios] = useState(false);
    const navigate = useNavigate();

    const handleOpenModal = (funct) => {
        funct(true);
    };

    const handleCloseModal = (funct) => {
        funct(false);
    };

    useEffect(() => {
        if (authState.permission != "ALL" && !authState.permission.includes("GERENCIAR MENSAGENS")) {
            navigate("/")
        }
    }, [])

    return (
        <S.CriarMensagemContainer>
            <S.ContainerTitle><span>MENSAGENS</span></S.ContainerTitle>

            <S.MensagensApp>
                <span>
                    Aqui você é capaz de configurar mensagens, enviar para um cliente, ou para todos, definir a duração e tipo.
                </span>
            </S.MensagensApp>

            <S.CreateMessageButton>
                <button className="bt1" onClick={() => { handleOpenModal(setIsModalOpenCriar) }}>CRIAR NOVA MENSAGEM</button>
                <button className="bt2" onClick={() => { handleOpenModal(setIsModalOpenVer) }}>VER MENSAGENS</button>
                <button className="bt3" onClick={() => { handleOpenModal(setIsModalOpenEnviar) }}>ENVIAR MENSAGEM</button>
                <button className="bt4" onClick={() => { handleOpenModal(setIsModalOpenVerEnvios) }}>ENVIOS</button>
            </S.CreateMessageButton>

            {isModalOpenCriar && <MensagemBox onClose={() => { handleCloseModal(setIsModalOpenCriar) }} />}

            {isModalOpenVer && <VerMensagens onClose={() => { handleCloseModal(setIsModalOpenVer) }} />}

            {isModalOpenEnviar && <EnviarMensagem onClose={() => { handleCloseModal(setIsModalOpenEnviar) }} />}

            {isModalOpenVerEnvios && <Envios onClose={() => { handleCloseModal(setIsModalOpenVerEnvios) }} />}

        </S.CriarMensagemContainer>
    );
}
