import React, { useEffect, useState, useContext } from 'react';
import * as Style from './ClientsStyle';
import { formatCPF, formatDateClients, formatNumber } from '../ASSETS/assets';
import CadastroPage from '../CadastroCliente/CriarCliente';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { AuthContext } from '../../Context/AuthContext';
import Loading from '../Loader';
import PaginaCliente from '../PaginaDoCliente/PaginaCliente';
import { useNavigate } from 'react-router-dom';

const base = process.env.REACT_APP_API_BASE_URL;
const get_client_data = process.env.REACT_APP_GET_CLIENT_DATA;

export default function Clientes() {
    const { getClients, clientsDataBase, totalClients, totalClientsWithMoney, authState } = useContext(AuthContext);
    const [clients, setClients] = useState([]);
    const [search, setSearch] = useState('');
    const [filterPages, setFilterPages] = useState(30);
    const [offset, setOffset] = useState(0);
    const [load, setLoad] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(10);
    const [orderFilter, setOrderFilter] = useState("decrescente");
    const [selectedClient, setSelectedClient] = useState(null);
    const [investmentFilter, setInvestmentFilter] = useState("");
    const navigate = useNavigate();

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = (currentPage - 1) * itemsPerPage;
    const currentClients = clientsDataBase.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        if (authState.permission != "ALL" && !authState.permission.includes("CRIAR/EDITAR CLIENTE")) {
            navigate("/")
        }

        if (clientsDataBase.length === 0) {
            setLoad(true)
            fetchClients();
            setLoad(false)
        }
    }, [])

    const fetchClients = async () => {
        try {
            setLoad(true)
            const response = await getClients(filterPages, offset, search, orderFilter, investmentFilter);
            setClients(response.data.data);
            setLoad(false)
        } catch (e) {
            setLoad(false)
        }
    };

    const handlePaginateFuture = async (type) => {
        var totalClientsCarregados = currentPage * itemsPerPage;
        if (type === "+") {
            if (totalClientsCarregados <= ((currentPage + 1) * itemsPerPage)) {
                setLoad(true)
                setFilterPages(filterPages + (itemsPerPage * 2))
                await fetchClients();
                console.log("obtendo mais clientes.")
                setLoad(false);
            }
            setCurrentPage(currentPage + 1);
        } else {
            if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
            }
        }
    }

    const backToInicio = () => {
        setCurrentPage(1);
    }


    return (
        <>
            {selectedClient && (
                <>
                    <PaginaCliente handleClose={() => setSelectedClient(null)} clienteData={selectedClient} setSelectedClient={setSelectedClient} setUsers={setClients} />
                </>
            )}
            <Style.ClientsContainer>
                <Loading load={load} />
                <Style.HomeInitialContent>
                    <Style.Boxes>
                        <Style.Box bgColor="#f2f2f2">
                            <Style.BoxContent>
                                <Style.BoxTitle> CLIENTES NA PLATAFORMA</Style.BoxTitle>
                                <span>{totalClients}</span>
                            </Style.BoxContent>
                        </Style.Box>

                        <Style.Box bgColor="#f2f2f2">
                            <Style.BoxContent>
                                <Style.BoxTitle>CLIENTES COM CONTRATOS ATIVOS</Style.BoxTitle>
                                <span>{totalClientsWithMoney}</span>
                            </Style.BoxContent>
                        </Style.Box>
                    </Style.Boxes>
                </Style.HomeInitialContent>

                <Style.ClientFirstContent>
                    <Style.AreaTitle><h5>CLIENTES</h5></Style.AreaTitle>
                    <Style.AddClient>+ ADICIONAR CLIENTE</Style.AddClient>
                </Style.ClientFirstContent>

                <Style.Clients>
                    <Style.SearchBar>
                        <input
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            type="text"
                            placeholder="Nome ou CPF do Cliente"
                        />

                        <button onClick={() => { fetchClients(); setCurrentPage(1) }}>PESQUISAR</button>
                    </Style.SearchBar>

                    <Style.SearchOrdem>
                        <div className='box'>
                            <span className='title'>ORDEM</span>
                            <select value={orderFilter} onChange={(e) => setOrderFilter(e.target.value)} className='selectFilter'>
                                <option value="desc">Decrescente</option>
                                <option value="asc">Crescente</option>
                            </select>
                            <button onClick={() => { fetchClients(); setCurrentPage(1) }} className='filterButton'>Filtrar</button>
                        </div>

                    </Style.SearchOrdem>
                    <Style.ClientsTable>

                        <Style.TableContainer>
                            <Style.Table>
                                <Style.TableHeader>
                                    <Style.TableRow>
                                        <Style.TableHeaderCell>DATA CAD.</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>NOME</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>CPF/CNPJ</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>E-MAIL</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>CELULAR</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>TOTAL INVESTIDO</Style.TableHeaderCell>
                                    </Style.TableRow>
                                </Style.TableHeader>
                                <Style.TableBody>
                                    {clientsDataBase.length > 0 ? (
                                        currentClients.map((client, index) => (
                                            <Style.TableRow onClick={() => setSelectedClient(client)} key={index} >
                                                <Style.TableCell>{formatDateClients(client.DATACRIACAO)}</Style.TableCell>
                                                <Style.TableCell>{client.NAME}</Style.TableCell>
                                                <Style.TableCell>{formatCPF(client.CPF)}</Style.TableCell>
                                                <Style.TableCell>{client.EMAIL}</Style.TableCell>
                                                <Style.TableCell>{client.CONTACT || 'não informado'}</Style.TableCell>
                                                <Style.TableCell>R$ {client.TOTAL_SPENT ? formatNumber(client.TOTAL_SPENT) : 0}</Style.TableCell>
                                            </Style.TableRow>
                                        ))
                                    ) : (
                                        <Style.TableRow>
                                            <Style.TableCell colSpan="8">Nenhum cliente encontrado</Style.TableCell>
                                        </Style.TableRow>
                                    )}
                                </Style.TableBody>
                            </Style.Table>
                        </Style.TableContainer>

                        <Style.PaginationContainer>
                            <Style.PaginationButton onClick={backToInicio}>
                                Início
                            </Style.PaginationButton>
                            <Style.PaginationButton onClick={() => { handlePaginateFuture("-"); }}>
                                Anterior
                            </Style.PaginationButton>

                            <Style.PaginationButton>
                                {indexOfFirstItem} à {currentPage * itemsPerPage} de {totalClients}
                            </Style.PaginationButton>

                            <Style.PaginationButton onClick={() => { handlePaginateFuture("+"); }}>
                                Próxima
                            </Style.PaginationButton>
                        </Style.PaginationContainer>
                    </Style.ClientsTable>
                </Style.Clients>
            </Style.ClientsContainer>
        </>
    );
}

