import styled from "styled-components";


export const FecharModal = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    // padding: 50px;
    margin-bottom: 30px;

    button{
        width: 150px;
        height: 35px;
        font-size: 16px;
        color: rgba(0,0,0,0.7);
        border: 0;
        border-radius: 8px;
        transition: .3s;
        font-weight: 600;
        background-color: #1e96fc;
        &:hover{
            transform: scale(0.95);
            color: black;
        }
    }
`;

export const ModalContent = styled.div`
    width: 100%;
    // max-height: max-content;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(-60deg, #313632, #000000 , #313632, #000000, #000000, #313632);
    padding: 10px 20px;
    box-sizing: border-box;
    overflow: auto;
    @media  (max-width: 1000px){
        padding: 30px 10px 50px 10px;
        overflow-y: auto;
    }
`;

export const ModalTitle = styled.h1`
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a2d6f9;
`;

export const SearchClient = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    padding: 0 20px;

    span {
        text-shadow: 2px 2px 1px rgba(0,0,0,0.4);
        font-size: 22px;
    }

    input {
        border: 0;
        width: 100%;
        box-sizing: border-box;
        box-shadow: 2px 2px 1px rgba(0,0,0,0.4);
        padding-left: 20px;
        height: 40px;
        font-size: 18px;
    }
`;

export const SelecionarClienteBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
    gap: 10px;
    background-color: white;
    box-shadow: 4px 4px 2px rgba(0,0,0,0.4);
`;

export const Cliente = styled.div`
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    transition: .3s;
    color: rgba(0,0,0,0.9);
    font-weight: 500;
    &:hover{
        color: white;
        background-color: rgba(0,0,0,0.7);
    }
`;

export const ClienteSelecionado = styled.div`
    margin-top: 40px;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const ClienteInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 5px;
    // max-height: 250px;
    // overflow-y: auto;
    align-items: center;

    span{
        font-weight: 600;
        color: #a2d6f9;
        font-size: 22px;
    }

    input {
        border: 0;
        width: 100%;
        box-sizing: border-box;
        box-shadow: 2px 2px 1px rgba(0,0,0,0.4);
        padding-left: 20px;
        height: 40px;
        font-size: 18px;
        text-align: center;
    }

    h6{
        margin: 0;
        font-size: 16px;
        transition: .3s;
        cursor: pointer;

        &:hover{
            transform: scale(0.97);
            color: rgba(20, 140, 255, 1);
        }
    }

    @media  (max-width: 1000px){

        span{
            font-size: 16px;
        }

        input {
            border: 0;
            width: 100%;
            box-sizing: border-box;
            box-shadow: 2px 2px 1px rgba(0,0,0,0.4);
            PADDING: 0;
            height: 40px;
            font-size: 18px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: CENTER;
        }
    }

    .tabelaColunas{
        width: 80%;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;

        .coluna{
            width: 100%;
            height: 40px;
            border: 2px solid white;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .tabelaLinhas{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // overflow: auto;
        height: max-content;

        .Linhas{
            width: 80%;
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
            transition: .3s;

            &:hover{
                background: rgba(240, 240, 240, 1);
                color: black;
                cursor: pointer;
            }

            .itemLinha{
                width: 100%;
                height: 40px;
                border: 2px solid white;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
`;

export const ButtonSaque = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    button{
        width: 100%;
        background: linear-gradient(to bottom, #72ce27, #b8f500);
        font-weight: 600;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
        transition: .3s;

        &:hover{
            transform: scale(0.95);
        }
    }
`;


export const Searching = styled.div`
    width: 100%;
    margin-top: 20px;

    div {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 0 20px;
        position: relative;

        p {
            margin: 0;
            color: rgba(0,0,0,0.8);
            font-weight: 600;
        }

        input {
            height: 40px;
            padding-left: 30px;
            box-sizing: border-box;
            font-size: 18px;
            color: rgba(0,0,0,0.7);
      
            border-radius: 3px;
            border: 0;
            box-shadow: 2px 1px 4px rgba(0,0,0,0.6);
        }

        .inputBoxSearch{
            width: 100%;
            display: grid;
            grid-template-columns: 8fr 1fr;
            box-sizing: border-box;

            input {
                height: 40px;
                padding-left: 30px;
                box-sizing: border-box;
                font-size: 18px;
                color: rgba(0,0,0,0.7);
        
                border-radius: 3px;
                border: 3px solid rgba(0,0,0,0.4);;
                box-shadow: 2px 1px 4px rgba(0,0,0,0.6);
            }

            button{
                width: 100%;
                height: 40px;
                box-sizing: border-box;
                border: 3px solid rgba(0,0,0,0.4);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
            }
        }
    }

    @media (max-width: 1000px){
        margin-top: 10px;
    }
`;


export const SearchedClients = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .columns{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 5fr 2fr 4fr 5fr;
        gap: 10px;
        background: linear-gradient(to right, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
        box-sizing: border-box;
        border-bottom: 3px solid blue;

        span{
            color: white;
            height: 40px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .rowSelected{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 5fr 2fr 4fr 5fr 1fr;
        cursor: pointer;
        transition: .3s;
        background: black;
        padding: 0;
        box-sizing: border-box;

        span{
            color: white;
            height: 40px;
            width: 100%;
            font-weight: 600;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            align-items: center;
        }

        &:hover{
            transform: scale(0.99);
            background: black;
        }
    }

    .tabelona{
        width: 100%;
        max-height: 450px;
        background: linear-gradient(to right, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        overflow-x: hidden;
        padding: 0;

        .row{
            width: 100%;
            display: grid;
        grid-template-columns: 1fr 5fr 2fr 4fr 5fr;
            cursor: pointer;
            transition: .3s;

            span{
                color: white;
                height: 40px;
                width: 100%;
                font-weight: 600;
                display: flex;
                justify-content: center;
                box-sizing: border-box;
                align-items: center;
            }

            &:hover{
                transform: scale(0.99);
                background: black;
            }
        }
    }
`;

export const SacarDoSelecionado = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    .titulo{
        font-size: 32px;
        color: white;
        font-weight: 600;
        text-align: center;
    }

    .inputSacar{
        width: 300px;
        height: 40px;
        border-radius: 6px;
        text-align: center;
        font-size: 22px;
        box-sizing: border-box;
        padding: 0;
        border: 0;
    }

    .buttonSacar{
        margin-top: 10px;
        width: 300px;
        height: 35px;
        border-radius: 6px;
        text-align: center;
        font-size: 22px;
        box-sizing: border-box;
        padding: 0;
        border: 0;
        background: rgba(80, 220, 0, 1);
        cursor: pointer;
        transition: .3s;

        &:hover{
            transform: scale(0.99);
        }
    }
`;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;
