import React, { useContext, useEffect, useState } from "react";
import * as H from './HomeStyle';
import JanelaSaque from "./Controllers/JanelaDeSaque/JanelaSaque";
import ValorUnidadeContrato from "./Controllers/ValorUniContrato/ValorUniContrato";
import Gateway from "./Controllers/Gateway/Gateway";
import Indicacao from "./Controllers/Indicacao/Indicacao";
import MinContratos from "./Controllers/MinContratos/MinContratos";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function HomeController() {
    const [selectedOption, setSelectedOption] = useState(''); // Estado para gerenciar o componente ativo
    const { authState } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (authState.permission != "ALL" && !authState.permission.includes("ACESSAR CONTROLADOR")) {
            navigate("/")
        }
    }, [])

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const renderComponent = () => {
        switch (selectedOption) {
            case 'JANELA_DE_SAQUES':
                return <JanelaSaque />;
            case 'VALOR_UNIDADE_CONTRATO':
                return <ValorUnidadeContrato />;
            case 'MIN_VALUE':
                return <MinContratos />;
            case 'GATEWAY':
                return <Gateway />
            case 'INDICACAO':
                return <Indicacao />
            default:
                return null;
        }
    };

    return (
        <H.HomeContainer>
            <H.HomeTitle><span>CONTROLADOR DA PLATAFORMA</span></H.HomeTitle>

            <H.HomeContent>
                <H.HomeOptions>
                    <H.StyledLink onClick={() => handleOptionClick('JANELA_DE_SAQUES')}>
                        <H.Option color="#1e96fc">CONTROLADOR DE SAQUES</H.Option>
                    </H.StyledLink>
                    <H.StyledLink onClick={() => handleOptionClick('VALOR_UNIDADE_CONTRATO')}>
                        <H.Option color="#fcf300">CONTRATO</H.Option>
                    </H.StyledLink>
                    <H.StyledLink onClick={() => handleOptionClick('INDICACAO')}>
                        <H.Option color="green">INDICAÇÃO</H.Option>
                    </H.StyledLink>
                    <H.StyledLink onClick={() => handleOptionClick('GATEWAY')}>
                        <H.Option color="#1e96fc">GATEWAY DE PAGAMENTO</H.Option>
                    </H.StyledLink>
                    <H.StyledLink onClick={() => handleOptionClick('MIN_VALUE')}>
                        <H.Option color="#1e96fc">COMPRA MÍNIMA</H.Option>
                    </H.StyledLink>
                </H.HomeOptions>

                <H.ControllerCentralize>
                    {renderComponent()}
                </H.ControllerCentralize>
            </H.HomeContent>
        </H.HomeContainer>
    );
}
