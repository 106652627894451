import React, { useContext, useEffect, useState } from "react";
import * as HomeStyle from './HomeStyle';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";

export default function Home() {
    const [opSelected, setOpSelected] = useState(null);
    const navigate = useNavigate();

    const handleOpClick = (op) => {
        setOpSelected(op);
    };

    const handleUnselect = () => {
        setOpSelected(null);
    };


    return (
        <>
            <HomeStyle.NewHomeContainer>
                <HomeStyle.NewHomeOptions>
                    <HomeStyle.NewHomeOptionBox onClick={() => handleOpClick("CLIENTES")}>
                        <div className="iconBox">
                            <img src="./clientes.png" />
                        </div>
                        <span className="name">CLIENTES</span>
                    </HomeStyle.NewHomeOptionBox>
                    <HomeStyle.NewHomeOptionBox onClick={() => handleOpClick("FERRAMENTAS")}>
                        <div className="iconBox">
                            <img src="./tools.png" />
                        </div>
                        <span className="name">FERRAMENTAS</span>
                    </HomeStyle.NewHomeOptionBox>
                    <HomeStyle.NewHomeOptionBox onClick={() => { navigate("/consultores") }}>
                        <div className="iconBox">
                            <img src="./staff-icon.png" />
                        </div>
                        <span className="name">CONSULTORES</span>
                    </HomeStyle.NewHomeOptionBox>
                    <HomeStyle.NewHomeOptionBox onClick={() => handleOpClick("CONTRATOS")}>
                        <div className="iconBox">
                            <img src="./contratos.png" />
                        </div>
                        <span className="name">CONTRATOS</span>
                    </HomeStyle.NewHomeOptionBox>
                    <HomeStyle.NewHomeOptionBox onClick={() => handleOpClick("SAQUES")}>
                        <div className="iconBox">
                            <img src="./saque.png" />
                        </div>
                        <span className="name">SAQUES</span>
                    </HomeStyle.NewHomeOptionBox>
                    <HomeStyle.NewHomeOptionBox onClick={() => { window.location.href = '/access' }}>
                        <div className="iconBox">
                            <img src="./increase-icon.png" />
                        </div>
                        <span className="name">ACESSOS</span>
                    </HomeStyle.NewHomeOptionBox>

                </HomeStyle.NewHomeOptions>
            </HomeStyle.NewHomeContainer>

            {opSelected && (
                <HomeStyle.NewHomeContent>
                    <span onClick={handleUnselect} style={{ cursor: "pointer", float: "right" }}>x</span>

                    {opSelected === "FERRAMENTAS" ? (
                        <>
                            <HomeStyle.StyledLink to="/indicacao">
                                <HomeStyle.Option>ADICIONAR INDICAÇÃO</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                            <HomeStyle.StyledLink to="/plusAnual">
                                <HomeStyle.Option>ADICIONAR PLUS</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                            <HomeStyle.StyledLink to="/mensagem">
                                <HomeStyle.Option>CRIAR MENSAGEM</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                            <HomeStyle.StyledLink to="/noticias">
                                <HomeStyle.Option>CRIAR NOTÍCIA</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                            <HomeStyle.StyledLink to="/anteciparLucro">
                                <HomeStyle.Option>ANTECIPAR LUCRO PRA SAQUE</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                        </>
                    ) : opSelected === "CLIENTES" ? (
                        <>
                            <HomeStyle.StyledLink to="/redefiniremail">
                                <HomeStyle.Option>REDEFINIR LOGIN</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                            <HomeStyle.StyledLink to="/clientes">
                                <HomeStyle.Option>TODOS OS CLIENTES</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                        </>
                    ) : opSelected === "CONTRATOS" ? (
                        <>
                            <HomeStyle.StyledLink to="/depositos">
                                <HomeStyle.Option>VALIDAR CONTRATOS</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                            <HomeStyle.StyledLink to="/contratos">
                                <HomeStyle.Option>VER CONTRATOS</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                        </>
                    ) : opSelected === "SAQUES" ? (
                        <>
                            <HomeStyle.StyledLink to="/saques">
                                <HomeStyle.Option>VALIDAR SAQUES</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                            <HomeStyle.StyledLink to="/saquesFeitos">
                                <HomeStyle.Option>VER SAQUES</HomeStyle.Option>
                            </HomeStyle.StyledLink>
                        </>
                    ) : null}

                </HomeStyle.NewHomeContent>
            )}
        </>

    );
}
