import React, { useEffect, useState, useContext } from 'react';
import * as Style from './AcessosStyle';
import { AuthContext } from '../../Context/AuthContext';
import Loading from "../Loader";
import { useNavigate } from 'react-router-dom';

export default function Acessos() {
    const { getAccess, access, totalAccess, authState } = useContext(AuthContext);
    const [clients, setClients] = useState([]);
    const [search, setSearch] = useState('');
    const [filterPages, setFilterPages] = useState(30);
    const [offset, setOffset] = useState(0);
    const [load, setLoad] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(10);
    const [orderFilter, setOrderFilter] = useState("desc");
    const navigate = useNavigate();
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = (currentPage - 1) * itemsPerPage;
    const currentClients = access.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        if (authState.permission != "ALL" && !authState.permission.includes("VER ACESSOS")) {
            navigate("/")
        }

        if (access.length === 0) {
            setLoad(true);
            fetchAccess();
            setLoad(false);
        }
    }, []);

    const fetchAccess = async () => {
        try {
            setLoad(true);
            const response = await getAccess(filterPages, offset, search, orderFilter);
            setLoad(false);
        } catch (e) {
            setLoad(false);
        }
    };

    const handlePaginateFuture = async (type) => {
        var totalClientsCarregados = currentPage * itemsPerPage;
        if (type === "+") {
            if (totalClientsCarregados <= ((currentPage + 1) * itemsPerPage)) {
                setLoad(true);
                setFilterPages(filterPages + (itemsPerPage * 2));
                await fetchAccess();
                console.log("Obtendo mais clientes.");
                setLoad(false);
            }
            setCurrentPage(currentPage + 1);
        } else {
            if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
            }
        }
    };

    const handleSearch = async () => {
        await fetchAccess();
    };

    const backToInicio = () => {
        setCurrentPage(1);
    };

    const formatDateClients = (date) => {
        if (!date) return "Data inválida";
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
    };

    const formatLastLogin = (timestamp) => {
        // Se for um objeto Timestamp do Firebase com método toDate()
        if (timestamp?.toDate) {
            return formatDateClients(timestamp.toDate());
        }
        // Se for um objeto com _seconds e _nanoseconds (serializado)
        else if (timestamp?._seconds) {
            const milliseconds = timestamp._seconds * 1000 + Math.floor(timestamp._nanoseconds / 1e6);
            const date = new Date(milliseconds);
            return formatDateClients(date);
        }
        return "Data inválida";
    };

    console.log(currentClients)

    return (
        <>
            <Style.ClientsContainer>
                <Loading load={load} />

                <Style.ClientFirstContent>
                    <Style.AreaTitle><h5>ACESSOS DOS CLIENTES</h5></Style.AreaTitle>
                </Style.ClientFirstContent>

                <Style.Clients>
                    <Style.SearchBar>
                        <input
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            type="text"
                            placeholder="Nome ou CPF do Cliente"
                        />

                        <button onClick={() => { fetchAccess(); setCurrentPage(1); }}>PESQUISAR</button>
                    </Style.SearchBar>

                    <Style.SearchOrdem>
                        <div className='box'>
                            <span className='title'>ORDEM</span>
                            <select value={orderFilter} onChange={(e) => setOrderFilter(e.target.value)} className='selectFilter'>
                                <option value="desc">Decrescente</option>
                                <option value="asc">Crescente</option>
                            </select>
                            <button onClick={() => { fetchAccess(); setCurrentPage(1); }} className='filterButton'>Filtrar</button>
                        </div>
                    </Style.SearchOrdem>
                    <Style.ClientsTable>

                        <Style.TableContainer>
                            <Style.Table>
                                <Style.TableHeader>
                                    <Style.TableRow>
                                        <Style.TableHeaderCell>NOME</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>CPF</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>USUÁRIO</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>ÚLTIMO ACESSO</Style.TableHeaderCell>
                                        <Style.TableHeaderCell>ACESSOS MENSAIS</Style.TableHeaderCell>
                                    </Style.TableRow>
                                </Style.TableHeader>
                                <Style.TableBody>
                                    {access.length > 0 ? (
                                        currentClients.map((client, index) => (
                                            <Style.TableRow key={index}>
                                                <Style.TableCell>{client.NAME}</Style.TableCell>
                                                <Style.TableCell>{client.CPF}</Style.TableCell>
                                                <Style.TableCell>{client.USERNAME}</Style.TableCell>
                                                <Style.TableCell>{formatLastLogin(client.LASTLOGIN)}</Style.TableCell>
                                                <Style.TableCell>{client.ACCESS}</Style.TableCell>
                                            </Style.TableRow>
                                        ))
                                    ) : (
                                        <Style.TableRow>
                                            <Style.TableCell colSpan="8">Nenhum cliente encontrado</Style.TableCell>
                                        </Style.TableRow>
                                    )}
                                </Style.TableBody>
                            </Style.Table>
                        </Style.TableContainer>

                        <Style.PaginationContainer>
                            <Style.PaginationButton onClick={backToInicio}>
                                Início
                            </Style.PaginationButton>
                            <Style.PaginationButton onClick={() => { handlePaginateFuture("-"); }}>
                                Anterior
                            </Style.PaginationButton>

                            <Style.PaginationButton>
                                {indexOfFirstItem} à {currentPage * itemsPerPage} de {totalAccess}
                            </Style.PaginationButton>

                            <Style.PaginationButton onClick={() => { handlePaginateFuture("+"); }}>
                                Próxima
                            </Style.PaginationButton>
                        </Style.PaginationContainer>
                    </Style.ClientsTable>
                </Style.Clients>
            </Style.ClientsContainer>
        </>
    );
}