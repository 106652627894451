import styled from "styled-components";




export const ValidarCredenciaisContainer = styled.div`
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    z-index: 999999;
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FecharValidacao = styled.div`
    position: absolute;
    top: 15px;
    right: 20px;

    span{
        font-size: 22px;
        transition: .3s;
        color: red;
        font-weight: 500;
        margin: 0;
        cursor: pointer;
        &:hover{
            color: white;
        }
    }
`;

export const ValidadacaoBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px 40px 20px 40px;
    min-width: 300px;
    border-radius: 6px;
    box-sizing: border-box;
    background: linear-gradient(-45deg, rgba(210, 210, 210, 1), rgba(190, 190, 190, 1), rgba(210, 210, 210, 1));
    position: relative;
    
    h1{
        font-weight: 500;
        font-size: 22px;
        margin: 0;
        color: black;
        font-weight: 600;
    }

    input{
        box-sizing: border-box;
        width: 100%;
        height: 35px;
        border-radius: 6px;
        text-align: center;
        font-size: 16px;
        border: 0;
        box-shadow: 3px 3px 1px rgba(0,0,0,0.4);
    }

    .buttonConfirm{
        margin-top: 10px;
        transition: .3s;
        height: 35px;
        border-radius: 6px;
        border: 0;
        background: rgba(100, 255, 0, 1);
        border: 2px solid black;
        cursor: pointer;

        &:hover{
            background: rgba(0, 200, 0, 1);
        }
    }
`;

export const CheckArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;

    label{
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: 0;
        gap: 5px;
        color: black;

        input{
            width: 16px;
            box-shadow: 0;
            height: 16px;
        }
        span{
            font-size: 16px;
            font-weight: 600;
        }
    }
`;

// export const nomecomponente = styled.div``;

// export const nomecomponente = styled.div``;

// export const nomecomponente = styled.div``;

// export const nomecomponente = styled.div``;

// export const nomecomponente = styled.div``;

// export const nomecomponente = styled.div``;
